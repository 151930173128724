@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.block {
	display: flex;
	flex-direction: column;
	max-width: units.px2rem(1170px);
	margin-inline: auto;
	margin-top: spacing.generate(10);

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

.block__content {
	padding: spacing.generate(6);
	background-color: colors.$gray-light;

	@include breakpoint.up(md) {
		padding: spacing.generate(8) spacing.generate(9) spacing.generate(8) spacing.generate(8);
	}
}

.block__content--narrow {
	display: flex;
	flex-direction: column;

	@include breakpoint.up(md) {
		min-height: units.px2rem(500px);
		padding: spacing.generate(5);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(8) spacing.generate(9) spacing.generate(8) spacing.generate(8);
	}
}

.block__col {
	position: relative;
	order: 2;
	width: 100%;

	@include breakpoint.up(md) {
		order: initial;
		width: 50%;
	}
}

.block__col--video {
	order: 1;
	min-height: units.px2rem(250px);

	@include breakpoint.up(md) {
		order: initial;
	}
}

.block__col--video-btn {
	position: absolute;
	top: 50%;
	right: 50%;
	z-index: 1;
	width: 100%;
	height: 100%;
	border: 0;
	background-color: transparent;
	transform: translate(50%, -50%);
	cursor: pointer;
}

.block__col--video-btn > svg {
	width: units.px2rem(75px);
	height: units.px2rem(75px);

	@include breakpoint.up(md) {
		width: units.px2rem(122px);
		height: units.px2rem(122px);
	}
}

.block__video,
.block__video-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
}

.block__video-overlay {
	object-fit: cover;
}

.block__video-overlay--top {
	object-position: top;
}

.block__video-overlay--right {
	object-position: right;
}

.block__video-overlay--center {
	object-position: center;
}

.block__video-overlay--left {
	object-position: left;
}

.block__title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;
	text-align: center;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 10);
		text-align: left;
	}
}

.block__text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.block__text:not(:last-of-type) {
	margin-bottom: spacing.generate(5);
}

.block__btn-wrap {
	margin-top: spacing.generate(8);
	text-align: center;

	@include breakpoint.up(md) {
		margin-top: spacing.generate(13);
		text-align: left;
	}
}

.block__content--narrow > .block__btn-wrap {
	@include breakpoint.up(md) {
		margin-top: auto;
	}
}

.block__socials {
	display: flex;
	gap: spacing.generate(3);
	justify-content: center;
	margin-bottom: spacing.generate(5);

	@include breakpoint.up(md) {
		gap: 0;
		justify-content: flex-start;
	}
}

.block__socials > li {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(2);
	align-items: center;
	min-width: units.px2rem(110px);
	font-size: map.get(typography.$font-size-values, -1);

	@include breakpoint.up(md) {
		flex-direction: row;
		min-width: initial;
		margin-right: spacing.generate(3);
	}
}

.block__socials > li > svg {
	width: units.px2rem(35px);
	height: units.px2rem(35px);
}

.block__socials > li > a {
	@include transitions.default('color');

	text-decoration: none;
	white-space: nowrap;
	color: colors.$black;

	&:hover {
		color: colors.$red;
	}
}
