@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/hover';
@use '../tools/spacing';
@use '../tools/units';

.table {
	display: flex;
	flex-flow: column;
	margin-inline: auto;
	margin-bottom: spacing.generate(7);
}

.table__thead {
	border-bottom: borders.$width solid map.get(colors.$grays, 800);
}

.table__tr {
	display: flex;
	flex-flow: wrap;
	width: 100%;
	border-bottom: borders.$width solid map.get(colors.$grays, 200);
}

.table__thead > .table__tr {
	border-bottom: 0;
}

.table__th,
.table__td {
	display: inline-flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);
	text-align: center;

	&:first-child {
		justify-content: flex-start;
		padding-left: 0;
		text-align: left;
	}

	&:last-child {
		justify-content: flex-end;
		padding-right: 0;
		text-align: right;
	}
}

.table__link {
	text-decoration: none;
	color: map.get(colors.$grays, 800);

	@include hover.generate() {
		&:hover {
			color: colors.$red;
		}
	}
}

.table--history .table {
	@include breakpoint.up(md) {
		width: units.px2rem(754px);
	}
}

.table--history .table__thead:not(.table__thead--title) {
	display: none;

	@include breakpoint.up(lg) {
		display: flex;
	}
}

.table--history .table__tr {
	padding-block: spacing.generate(3);

	@include breakpoint.up(lg) {
		padding-block: 0;
	}
}

.table--history .table__th,
.table--history .table__td {
	@include breakpoint.up(lg) {
		min-height: units.px2rem(66px);
	}
}

.table--history .table__title {
	width: 100%;
	margin-bottom: 0;
	font-size: map.get(typography.$font-size-values, 3);
	text-align: left;
}

.table--history .table__th--id,
.table--history .table__td--id {
	min-width: units.px2rem(110px);
}

.table--history .table__td--id {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.table--history .table__td--date,
.table--history .table__td--price {
	white-space: nowrap;
}

.table--history .table__td--date {
	justify-content: center;
	padding-right: spacing.generate(2);
	text-align: center;
}

.table--history .table__td--amount {
	display: none;

	@include breakpoint.up(sm) {
		display: inline-flex;
	}
}

.table--history .table__th--price,
.table--history .table__td--price {
	padding-right: 0;
	text-align: right;

	@include breakpoint.up(lg) {
		padding-right: spacing.generate(2);
	}
}

.table--history .table__th--status,
.table--history .table__td--status {
	flex-basis: 100%;
	justify-content: flex-start;
	padding-left: 0;
	text-align: left;

	@include breakpoint.up(lg) {
		flex: 1;
		justify-content: center;
		min-width: units.px2rem(146px);
		padding-left: spacing.generate(2);
		text-align: center;
	}
}

.table--history .table__th--detail,
.table--history .table__td--detail {
	max-width: units.px2rem(80px);
	margin-left: auto;
}

.table--history .table__th--invoice,
.table--history .table__td--invoice {
	max-width: units.px2rem(92px);
}

.table--history-head {
	padding: spacing.generate(4);
	border-radius: map.get(borders.$radius-values, large);
	background-color: map.get(colors.$grays, 200);
}

.table--history-head .table__tr {
	border-bottom: 0;
}

.table--history-head .table__th,
.table--history-head .table__td {
	justify-content: flex-start;
	padding-inline: 0 spacing.generate(2);
	padding-block: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, -1);
	text-align: left;
}

.table--history-head .table__td {
	max-width: units.px2rem(90px);
}

.table--history-head .table__th {
	max-width: units.px2rem(200px);
}

.table--history-head .table__td--status {
	min-width: 100%;
	max-width: none;
	padding-bottom: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 1);

	@include breakpoint.up(sm) {
		min-width: 0;
	}
}

.table--history-detail {
	margin-bottom: spacing.generate(3);
}

.table--history-detail .table__tr {
	padding-block: spacing.generate(3);

	&:last-child {
		border-bottom-color: map.get(colors.$grays, 800);
	}
}

.table--history-detail .table__td--img {
	max-width: units.px2rem(104px);
	padding-inline: 0;
	margin-bottom: auto;
}

.table--history-detail .table__td--location {
	flex-flow: column;
	align-items: flex-start;
	min-width: calc(100% - #{units.px2rem(104px)});
	font-size: map.get(typography.$font-size-values, -1);
	text-align: left;

	@include breakpoint.up(md) {
		min-width: 0;
	}
}

.table--history-detail .table__td-blank {
	display: none;

	@media (min-width: #{units.px2rem(390px)}) {
		display: inline-flex;
		min-width: units.px2rem(104px);
	}

	@include breakpoint.up(md) {
		display: none;
	}
}

.table--history-detail .table__td--date {
	justify-content: flex-start;
	max-height: units.px2rem(40px);
	padding-inline: spacing.generate(2) 0;
	font-size: map.get(typography.$font-size-values, -1);
	text-align: left;

	@include breakpoint.up(md) {
		align-items: flex-start;
		justify-content: flex-end;
		max-width: units.px2rem(130px);
		max-height: none;
		padding-top: spacing.generate(3);
		text-align: right;
	}
}

.table--history-detail .table__td--price {
	justify-content: flex-end;
	max-width: units.px2rem(105px);
	max-height: units.px2rem(40px);
	padding: 0;
	font-size: map.get(typography.$font-size-values, -1);
	text-align: right;

	@include breakpoint.up(md) {
		align-items: flex-start;
		max-height: none;
		padding-block: spacing.generate(3) 0;
	}
}

.table--history-detail .table__td--trash {
	max-width: units.px2rem(35px);
	max-height: units.px2rem(40px);
	padding: 0;

	@include breakpoint.up(md) {
		align-items: flex-start;
		max-height: none;
		padding-top: units.px2rem(2px);
	}
}

.table--history-detail .table__location-title {
	margin-bottom: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, 0);
}

.table--history-detail .table__location-id {
	margin-block: spacing.generate(1);
	color: map.get(colors.$grays, 600);
}

.table--history-detail .table__location-halfMonth {
	position: relative;
	padding-left: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, -2);
	line-height: map.get(typography.$line-height-values, 1);
	color: map.get(colors.$grays, 600);

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: units.px2rem(8px);
		height: units.px2rem(8px);
		transform: translateY(-50%);
	}

	&::before {
		border-width: 0 0 units.px2rem(8px) units.px2rem(8px);
		border-style: solid;
		border-color: map.get(colors.$grays, 500) white;
		transform: translateY(-50%) rotate(135deg);
	}

	&::after {
		border-radius: units.px2rem(8px);
		box-shadow:
			0 0 0 units.px2rem(1px) white,
			0 0 0 units.px2rem(2px) map.get(colors.$grays, 500);
	}
}

.table--history-detail .table__button-trash {
	padding-block: spacing.generate(2);
	padding-left: spacing.generate(2);
	font-size: 0;
	line-height: 0;
}

.table--history-detail .table__icon svg {
	color: map.get(colors.$grays, 800);
}

.table--history-detail .table__tfoot > .table__tr {
	padding-block: 0;

	&:last-child {
		border-bottom: 0;
	}
}

.table--history-detail .table__tfoot > .table__td {
	align-items: flex-end;
	min-height: 0;
	padding-block: spacing.generate(3) 0;
}

.table--history-detail .table__td--total-title {
	font-size: map.get(typography.$font-size-values, 3);
}

.table--history-detail .table__td--total-price {
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 3);
	}
}
