@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.tooltip--inline {
	display: inline-flex;
	padding-left: spacing.generate(2);
}

.tooltip > svg {
	width: units.px2rem(22px);
	height: units.px2rem(22px);
}

.tooltip > .tooltip__text {
	@include transitions.default('opacity');

	position: absolute;
	bottom: 125%;
	left: 0;
	z-index: 1;
	width: units.px2rem(140px);
	padding: spacing.generate(2);
	margin-right: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, -2);
	text-align: center;
	white-space: normal;
	color: colors.$white;
	border-radius: units.px2rem(8px);
	background-color: map.get(colors.$grays, 700);
	visibility: hidden;
	opacity: 0;
	transform: translateX(-45%);

	@include breakpoint.up(sm) {
		width: units.px2rem(160px);
		transform: translateX(-53%);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(170px);
		transform: translateX(-50%);
	}
}

.tooltip--inline > .tooltip__text {
	bottom: 50%;
	left: calc(100% + #{spacing.generate(2)});
	margin-right: 0;
	text-align: left;
	transform: translate(0, 50%);

	@include breakpoint.up(lg) {
		width: units.px2rem(220px);
	}
}

.tooltip > .tooltip__text::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	border-width: spacing.generate(1);
	border-style: solid;
	border-color: map.get(colors.$grays, 700) transparent transparent transparent;
	transform: translateX(-50%);
}

.tooltip--inline > .tooltip__text::after {
	top: 50%;
	left: units.px2rem(-9px);
	transform: translate(0, -50%) rotate(90deg);
}

.tooltip:hover > .tooltip__text {
	visibility: visible;
	opacity: 1;
}
