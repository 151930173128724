@use 'sass:map';
@use '../settings/colors';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.partner {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	margin: units.px2rem(-7px);

	@include breakpoint.up(sm) {
		margin: spacing.generate(-4);
	}

	@include breakpoint.up(lg) {
		justify-content: space-between;
	}
}

.partner__item {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: units.px2rem(60px);
	margin: units.px2rem(7px);
	color: map.get(colors.$grays, 500);

	@include breakpoint.up(sm) {
		margin: spacing.generate(4);
	}
}

.partner__item > svg {
	max-width: 85%;

	@include breakpoint.up(sm) {
		max-width: 100%;
	}
}
