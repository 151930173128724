@font-face {
	font-style: normal;
	font-weight: 400;
	font-family: Inter;
	font-display: swap;
	src:
		url('../../fonts/Inter-Regular.woff') format('woff'),
		url('../../fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
	font-style: normal;
	font-weight: 500;
	font-family: Inter;
	font-display: swap;
	src:
		url('../../fonts/Inter-Medium.woff') format('woff'),
		url('../../fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
	font-style: normal;
	font-weight: 700;
	font-family: Inter;
	font-display: swap;
	src:
		url('../../fonts/Inter-Bold.woff') format('woff'),
		url('../../fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
	font-style: normal;
	font-weight: 700;
	font-family: Zuume;
	font-display: swap;
	src:
		url('../../fonts/Zuume-Bold.woff') format('woff'),
		url('../../fonts/Zuume-Bold.woff2') format('woff2');
}

@font-face {
	font-style: normal;
	font-weight: 800;
	font-family: Zuume;
	font-display: swap;
	src:
		url('../../fonts/Zuume-ExtraBold.woff') format('woff'),
		url('../../fonts/Zuume-ExtraBold.woff2') format('woff2');
}
