@use 'sass:map';
@use '../settings/colors';
@use '../settings/borders';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/hover';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

@keyframes jump {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-0.5rem);
	}

	100% {
		transform: translateY(0);
	}
}

.map {
	width: 100%;
	height: 100%;
	border-top-left-radius: map.get(borders.$radius-values, large);
	border-bottom-left-radius: map.get(borders.$radius-values, large);

	@include breakpoint.up(md) {
		position: absolute;
	}
}

.leaflet-tile-container {
	filter: grayscale(100%);
}

.map__marker {
	@include transitions.default('transform');

	display: block;
	color: colors.$blue-dark;
	cursor: pointer;
}

.map__marker.is-hover {
	z-index: 3;
	animation: jump 500ms ease-in-out 0s infinite normal;
}

.map__marker--detail {
	z-index: 1;
	color: map.get(colors.$grays, 800);
}

.map__marker--cart {
	color: colors.$red;
}

.map__marker--visited {
	opacity: 0.35;
}

.map__marker--approximate {
	color: colors.$blue;
}

.map .card.card,
.detail__map .card.card {
	display: block;
	width: units.px2rem(310px);
	height: auto;
	padding: 0;
	border-radius: map.get(borders.$radius-values, large);
	background-color: colors.$white;
}

.map .leaflet-popup-close-button,
.detail__map .leaflet-popup-close-button {
	display: none;
}

.map .card-header,
.detail__map .card-header {
	position: relative;
	display: block;
	height: units.px2rem(110px);

	@include breakpoint.up(sm) {
		height: units.px2rem(175px);
	}
}

.map .card .card-body.card-body,
.detail__map .card .card-body.card-body {
	// stylelint-disable-next-line declaration-no-important
	height: auto !important;
	overflow: visible;
}

.map .card-body .card-box,
.detail__map .card-body .card-box {
	height: auto;
}

.cluster.cluster {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 1);
	font-family: typography.$font-family-primary;
	color: colors.$blue;
	border: units.px2rem(2px) solid colors.$blue;
	border-radius: 50%;
	background-color: colors.$white;
	box-shadow: 0 0 spacing.generate(3) 0 #{rgba(colors.$black, 0.5)};
}

.map > .map__switch {
	position: absolute;
	top: 50%;
	left: spacing.generate(-1);
	z-index: 100;
	width: units.px2rem(24px);
	height: units.px2rem(24px);
	border-radius: 50%;
	background-color: colors.$white;
	transform: translateY(-50%);

	&::after,
	&::before {
		content: '';
		position: absolute;
		right: units.px2rem(13px);
		width: units.px2rem(1px);
		height: units.px2rem(11px);
		background-color: map.get(colors.$grays, 600);
	}

	&::after {
		top: units.px2rem(10px);
		transform: rotate(-45deg);
	}

	&::before {
		top: units.px2rem(2px);
		transform: rotate(45deg);
	}
}

.map .leaflet-popup-content-wrapper,
.detail__map .leaflet-popup-content-wrapper {
	padding: 0;
}

.map .leaflet-popup-content,
.detail__map .leaflet-popup-content {
	margin: 0;
}

.map .btn.btn,
.detail__map .btn.btn {
	color: colors.$white;
}

.map .detail__map__btn.detail__map__btn,
.detail__map .detail__map__btn.detail__map__btn {
	color: map.get(colors.$grays, 800);
}

.map .product__link.product__link,
.detail__map .product__link.product__link {
	text-decoration: none;
	color: map.get(colors.$grays, 800);

	@include hover.generate() {
		&:hover {
			color: colors.$red;
		}
	}
}
