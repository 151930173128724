@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';
@use '../tools/units';
@use '../tools/transitions';

.autocomplete__list {
	position: absolute;
	z-index: 100;
	width: 100%;
	border: units.px2rem(1px) solid;
	border-top: 0;
	border-radius: units.px2rem(5px);
	background-color: colors.$white;
}

.autocomplete__item {
	@include transitions.default('background-color');

	padding: spacing.generate(2) spacing.generate(3);
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: map.get(colors.$grays, 200);
	}
}

.autocomplete__item:not(:last-of-type) {
	border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 300);
}

.autocomplete__item > mark {
	font-weight: map.get(typography.$font-weight-values, bold);
	background-color: transparent;
}
