@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.spec {
	display: flex;
	flex-flow: row wrap;
	padding-inline: spacing.generate(6);
	padding-bottom: spacing.generate(6);

	@include breakpoint.up(xl) {
		padding-inline: spacing.generate(12);
		padding-bottom: spacing.generate(12);
	}
}

.spec__title {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.spec__subtitle {
	font-size: map.get(typography.$font-size-values, 2);
}

.spec__image-wrap {
	position: relative;
	width: 100%;

	@include breakpoint.up(lg) {
		width: 60%;
	}
}

.spec__image-wrap > svg {
	position: absolute;
	top: spacing.generate(4);
	left: spacing.generate(8);
	width: auto;
	height: 100%;
}

.spec__figure {
	width: 100%;
}

.spec__img {
	max-width: 100%;
}

.spec__text {
	display: inline-flex;
	flex-flow: column wrap;
	width: 100%;
	padding-top: spacing.generate(6);

	@include breakpoint.up(lg) {
		width: 40%;
		padding-top: 0;
		padding-left: spacing.generate(4);
	}

	@include breakpoint.up(xxl) {
		padding-left: spacing.generate(8);
	}
}

.spec__list {
	margin-bottom: spacing.generate(6);
}

.spec__list-item {
	position: relative;
	padding: spacing.generate(1) 0 spacing.generate(1) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 0);
	}

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: units.px2rem(4px);
		height: units.px2rem(4px);
		border-radius: 50%;
		background-color: colors.$black;
		transform: translateY(-50%);
	}
}
