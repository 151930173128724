@use 'sass:map';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';

.history-detail {
	margin-top: spacing.generate(20);

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(16);
	}
}

.history-detail__title {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 3);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(11);
	}
}

.history-detail__content {
	display: flex;
	flex-flow: wrap;
	width: 100%;
}

.history-detail__column {
	width: 100%;
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(sm) {
		width: 50%;
	}
}

.history-detail__column--full {
	width: 100%;
}

.history-detail__subtitle {
	font-size: map.get(typography.$font-size-values, 1);
}

.history-detail__block {
	margin-bottom: spacing.generate(6);
}
