@use 'sass:string';
@use '../settings/transitions';

@mixin default(
	$property,
	$transition-duration: transitions.$duration,
	$delay: 0ms
) {
	transition: string.unquote($property) $transition-duration transitions.$timing-function $delay;
}
