@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.cart-address__invoice-title {
	margin-block: spacing.generate(4) spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 3);

	@include breakpoint.up(sm) {
		margin-block: spacing.generate(8) spacing.generate(3);
	}
}

.cart-address__invoice-title--tooltip {
	display: flex;
	align-items: center;
}

.cart-address__sign-row {
	display: flex;
	justify-content: space-between;
}

.cart-address__sign-textfield {
	padding-top: spacing.generate(1);
}

.cart-address__sign-text {
	text-decoration-color: inherit;
	color: inherit;
}

.cart-address__short-field {
	max-width: units.px2rem(380px);
}

.cart-address__shorter-field {
	max-width: units.px2rem(250px);
}

.cart-address__btn-continue {
	display: block;
	margin-block: spacing.generate(10) spacing.generate(5);

	@include breakpoint.up(sm) {
		display: none;
	}
}

.cart-address__brands {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: spacing.generate(7);

	@include breakpoint.up(sm) {
		display: none;
	}
}

.cart-address__payment {
	display: none;

	@include breakpoint.up(sm) {
		display: flex;
		flex-direction: column;
		align-self: flex-start;
		max-width: units.px2rem(380px);
		padding: spacing.generate(5);
		margin-bottom: spacing.generate(4);
		font-size: map.get(typography.$font-size-values, -1);
		border-radius: map.get(borders.$radius-values, large);
		background-color: map.get(colors.$grays, 200);
	}
}

.cart-address__payment-title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 1);
}

.cart-address__payment-row {
	display: flex;
	gap: spacing.generate(3);
	justify-content: space-between;
	padding: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, -1);
	border: 1px solid map.get(colors.$grays, 600);
	border-radius: map.get(borders.$radius-values, default);
	cursor: pointer;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 0);
	}
}

.cart-address__payment-row > svg {
	width: units.px2rem(40px);
	height: auto;
}

.cart-address__payment-field {
	min-width: units.px2rem(170px);
}

.cart-address__payment-input:checked ~ .cart-address__payment-row {
	color: colors.$white;
	background-color: colors.$blue;
}

.cart-address__text-muted {
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);
	color: map.get(colors.$grays, 600);
}

.cart-address__payment-input:checked ~ .cart-address__payment-row .cart-address__text-muted {
	color: colors.$white;
}

.cart-address__payment-type {
	align-self: center;
}

.cart-address__payment-note {
	margin-bottom: spacing.generate(4);
	color: colors.$blue;
}

.cart-address__text-terms {
	font-size: map.get(typography.$font-size-values, -2);
	text-align: center;
	color: map.get(colors.$grays, 600);
}

.cart-address__link-terms {
	font-size: map.get(typography.$font-size-values, -2);
	text-decoration-color: map.get(colors.$grays, 600);
	color: map.get(colors.$grays, 600);
}

.cart-address__order-right {
	display: none;

	@include breakpoint.up(sm) {
		display: block;
	}
}

.cart-address__order.switched > .cart-address__order-right {
	display: block;

	@include breakpoint.up(sm) {
		display: none;
	}
}

.cart-address__order.switched > .cart-address__order-left {
	display: none;

	@include breakpoint.up(sm) {
		display: block;
	}
}
