$red: #e5000f;
$red-light: #fa7674;
$blue: #0096e5;
$blue-dark: #0b71b9;
$blue-light: #4188b6;
$warning-dark: #f5a124;
$warning: #fdcf0f;
$warning-light: #ede68b;
$green-light: #67c72f;
$green-lighter: #97c77b;
$green: #79bd8f;
$gray-light: #e6e6e6;

$grays: (
	200: #eee,
	300: #d0d0d0,
	400: #d9d9d9,
	500: #9b9b9b,
	600: #999,
	700: #535252,
	800: #333,
);

$black: #000;
$white: #fff;
