@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.hero {
	position: relative;
	padding-block: spacing.generate(9) spacing.generate(10);
	overflow: hidden;
	background-color: colors.$red;
}

.hero__inner {
	position: relative;
	align-self: flex-end;
	width: 100%;
	text-align: center;

	@include breakpoint.up(md) {
		align-self: center;
		margin-top: units.px2rem(30px);
	}

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.hero__sticker {
	position: relative;
	margin-top: spacing.generate(2);

	@include breakpoint.up(md) {
		position: absolute;
		top: 32%;
		right: units.px2rem(220px);
		margin-top: unset;
	}

	@include breakpoint.up(lg) {
		top: 32%;
		right: units.px2rem(345px);
	}
}

.hero__sticker picture {
	position: absolute;
	width: 100%;
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 2s ease-in-out;
}

.hero__sticker img {
	max-width: units.px2rem(250px);
	height: auto;

	@include breakpoint.up(lg) {
		max-width: units.px2rem(360px);
	}
}

.is-sticker-active.is-sticker-active {
	opacity: 1;
}

.hero__title {
	max-width: 18ch;
	margin: 0 auto;
	font-weight: map.get(typography.$line-height-values, bold);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;
	letter-spacing: units.px2rem(-1px);
	color: colors.$white;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 11);
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 13);
	}
}

.hero__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}

.hero__image {
	width: 100%;
	margin-block: spacing.generate(2) spacing.generate(8);

	@include breakpoint.up(md) {
		margin-block: spacing.generate(-2) spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.hero__image > svg {
	width: units.px2rem(350px);
	max-width: 100%;
	height: auto;

	@include breakpoint.up(md) {
		width: units.px2rem(600px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(900px);
	}
}

.hero__buttons {
	display: inline-flex;
	flex-direction: column;
	gap: spacing.generate(4);
	max-width: units.px2rem(220px);
	margin: 0 auto;

	@include breakpoint.up(md) {
		flex-direction: row;
		max-width: 100%;
	}

	@include breakpoint.up(lg) {
		gap: spacing.generate(14);
	}
}

.hero__cta {
	display: flex;
	align-self: flex-end;
	width: 100%;
	padding-top: spacing.generate(25);

	@include breakpoint.up(md) {
		padding-top: 0;
	}
}

.hero__cta__list {
	display: flex;
	grid-column-gap: spacing.generate(3);
	justify-content: space-between;
	width: 100%;
	margin-top: spacing.generate(8);
}

.hero__cta__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 25%;
	color: colors.$white;

	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.hero__cta__item > svg {
	width: units.px2rem(45px);
	height: units.px2rem(45px);

	@include breakpoint.up(lg) {
		width: units.px2rem(60px);
		height: units.px2rem(60px);
	}
}

.hero__cta__text {
	margin-top: spacing.generate(2);
	font-weight: map.get(typography.$line-height-values, regular);
	font-size: map.get(typography.$font-size-values, -3);
	text-align: center;

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$line-height-values, medium);
		font-size: map.get(typography.$font-size-values, -1);
	}

	@include breakpoint.up(lg) {
		margin-left: spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 0);
		text-align: left;
	}
}

.hero__buttons > .hero__buttons__icon > svg {
	display: none;

	@include breakpoint.up(lg) {
		display: block;
		margin-block: spacing.generate(-3);
		margin-right: spacing.generate(2);
	}
}

.hero__buttons__icon:hover {
	color: colors.$white;
	background-color: colors.$black;
}
