@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.infobox {
	display: flex;
	padding: spacing.generate(2) spacing.generate(7) spacing.generate(2) spacing.generate(2);
	margin-bottom: spacing.generate(10);
	border-radius: map.get(borders.$radius-values, large);
	background-color: colors.$green-light;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(14);
	}
}

.infobox--inverted {
	color: colors.$green-light;
	background-color: transparent;
}

.infobox--warning {
	background-color: colors.$warning;
}

.infobox--warning-light {
	border: borders.$width solid colors.$warning-dark;
	background-color: #{rgba(colors.$warning, 0.2)};
}

.infobox--error {
	color: colors.$white;
	background-color: colors.$red;
}

.infobox__icon {
	height: units.px2rem(42px);
	padding-right: spacing.generate(3);
}

.infobox__icon > svg {
	display: block;
	width: units.px2rem(42px);
	height: auto;
}

.infobox__icon--spacy {
	height: units.px2rem(50px);
	padding-right: spacing.generate(6);
}

.infobox__icon--spacy > svg {
	width: units.px2rem(50px);
}

.infobox--warning-light > .infobox__icon {
	width: units.px2rem(20px);
	height: units.px2rem(20px);
	padding-right: 0;
	margin-inline: spacing.generate(2);
	color: colors.$warning-dark;
}

.infobox--warning-light > .infobox__icon > svg {
	display: block;
	width: 100%;
	height: 100%;
}

.infobox__title {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
}

.infobox__text {
	font-size: map.get(typography.$font-size-values, -1);
}

.infobox__text--large {
	font-size: map.get(typography.$font-size-values, 0);
}

.infobox__text--larger {
	font-size: map.get(typography.$font-size-values, 1);
}

.infobox__title,
.infobox__text {
	line-height: map.get(typography.$line-height-values, 1);
}

.infobox__text--strong {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.infobox--mobile {
	display: flex;
	margin-block: spacing.generate(6);

	@include breakpoint.up(sm) {
		display: none;
	}
}
