@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.cart__grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: spacing.generate(5);
}

.cart__grid--nowrap {
	flex-wrap: nowrap;
}

.cart__container {
	margin-block: spacing.generate(2);

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(10);
	}
}

.cart__container-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: spacing.generate(3);
}

.cart__item-desc {
	@include breakpoint.up(lg) {
		display: flex;
		flex-grow: 5;
		flex-direction: row;
		justify-content: space-between;
	}
}

.cart__area-desc {
	max-width: units.px2rem(320px);
	margin-right: 0;

	@include breakpoint.up(sm) {
		margin-right: spacing.generate(7);
	}
}

.cart__area-address {
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);
}

.cart__area-number {
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);
	color: map.get(colors.$grays, 500);
}

.cart__area-halfMonth {
	position: relative;
	padding-left: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, -2);
	line-height: map.get(typography.$line-height-values, 1);
	color: map.get(colors.$grays, 500);

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: units.px2rem(8px);
		height: units.px2rem(8px);
		transform: translateY(-50%);
	}

	&::before {
		border-width: 0 0 units.px2rem(8px) units.px2rem(8px);
		border-style: solid;
		border-color: map.get(colors.$grays, 500) white;
		transform: translateY(-50%) rotate(135deg);
	}

	&::after {
		border-radius: units.px2rem(8px);
		box-shadow:
			0 0 0 units.px2rem(1px) white,
			0 0 0 units.px2rem(2px) map.get(colors.$grays, 500);
	}
}

.cart__spec {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
}

.cart__spec-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: spacing.generate(4);

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(0);
	}
}

.cart__spec-month {
	min-width: units.px2rem(130px);

	@include breakpoint.up(sm) {
		min-width: units.px2rem(140px);
	}
}

.cart__inquiry-item {
	display: flex;
	align-items: center;
}

.cart__icon-trash {
	margin-left: spacing.generate(1);

	@include breakpoint.up(sm) {
		margin-left: spacing.generate(5);
	}
}

.cart__divider {
	height: units.px2rem(1px);
	margin-block: spacing.generate(4);
	color: map.get(colors.$grays, 200);
	border: 0;
	background-color: map.get(colors.$grays, 200);
}

.cart__divider-price {
	width: 100%;
	margin-block: spacing.generate(1);

	@include breakpoint.up(sm) {
		margin-block: spacing.generate(2);
	}
}

.cart__offer-content {
	align-self: center;
	margin-bottom: 0;
	background-color: transparent;

	@include breakpoint.up(sm) {
		display: flex;
		flex-direction: column;
		max-width: units.px2rem(370px);
		padding: spacing.generate(6) spacing.generate(5);
		margin-bottom: spacing.generate(4);
		font-size: map.get(typography.$font-size-values, -1);
		border-radius: map.get(borders.$radius-values, large);
		background-color: map.get(colors.$grays, 200);
	}
}

.cart__offer-text {
	margin-bottom: spacing.generate(5);
}

.cart__offer-link {
	display: inline-block;
	text-decoration-color: inherit;
	color: inherit;
}

.cart__offer-table {
	width: 100%;
	margin-bottom: spacing.generate(5);
}

.cart__offer-table td {
	padding-bottom: spacing.generate(2);
	vertical-align: top;
}

.cart__offer-hide {
	visibility: hidden;

	@include breakpoint.up(sm) {
		visibility: visible;
	}
}

.cart__offer-show {
	margin-top: spacing.generate(30);
	font-size: map.get(typography.$font-size-values, -1);
	color: map.get(colors.$grays, 600);
	visibility: visible;

	@include breakpoint.up(sm) {
		margin-top: 0;
	}
}

.cart__offer-show tr:last-child {
	height: spacing.generate(8);
	border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 800);
}

.cart__voucher-box {
	max-width: units.px2rem(170px);
}

.cart__voucher-form {
	display: none;
}

.cart__voucher-form.show {
	display: block;
	max-width: units.px2rem(150px);
	margin-top: spacing.generate(1);
}

.cart__voucher-submit {
	margin-top: spacing.generate(1);
}

.cart__voucher-remove {
	float: right;
	color: map.get(colors.$grays, 800);
}

.cart__offer-price {
	font-size: map.get(typography.$font-size-values, 1);
}

.cart__offer-price-right {
	text-align: right;
}

.cart__offer-vat {
	font-size: map.get(typography.$font-size-values, -1);
	color: map.get(colors.$grays, 500);
}

.cart__brands {
	display: none;

	@include breakpoint.up(sm) {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-block: spacing.generate(2);
	}
}

.cart__img {
	margin-right: spacing.generate(1);

	@include breakpoint.up(sm) {
		margin-right: spacing.generate(3);
	}
}

.cart__img-area img {
	width: 100%;
	min-width: units.px2rem(104px);
}

.cart__empty {
	margin-top: spacing.generate(20);
}

.cart__empty-text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
}

.cart-box {
	width: calc(100% + #{units.px2rem(30px)});
	margin-inline: spacing.generate(-3);
	margin-block: spacing.generate(4);
}

.cart-box__wrap {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	max-width: units.px2rem(640px);
	padding: spacing.generate(4);
	border-radius: map.get(borders.$radius-values, default);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(md) {
		padding: spacing.generate(3) spacing.generate(6);
	}
}

.cart-box__content__title {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	color: map.get(colors.$grays, 800);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 3);
	}
}

.cart-box__content__list {
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
	color: colors.$blue;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.cart-box__content__link {
	text-decoration: none;
}

.cart-box__image-wrap {
	padding-left: spacing.generate(6);
	border-left: units.px2rem(1px) dashed map.get(colors.$grays, 300);
}

.cart-box__image {
	width: 100%;
	height: auto;
}

.cart-box__wrap--empty {
	margin-inline: auto;
	margin-block: spacing.generate(40) 0;
}

.cart__info {
	margin-block: spacing.generate(3) spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	text-align: right;
}
