@use '../tools/breakpoint';

.display-sm-none {
	display: none;

	@include breakpoint.up(sm) {
		display: block;
	}
}

.display-sm {
	display: block;

	@include breakpoint.up(sm) {
		display: none;
	}
}

.display-md-none {
	display: none;

	@include breakpoint.up(md) {
		display: block;
	}
}
