@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography' as typography-settings;
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/typography';
@use '../tools/transitions';
@use '../tools/units';

.types__wrap {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	max-width: units.px2rem(1440px);
	margin-inline: auto;
	overflow: hidden;
	border-radius: map.get(borders.$radius-values, large);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

/* stylelint-disable selector-not-notation */
.types__select:not(:first-child):not(:checked) ~ div {
	display: none;
}
/* stylelint-enable */

.types__select-grid {
	position: relative;
	display: flex;
	flex-direction: column;

	@include breakpoint.up(sm) {
		flex-direction: row;
		gap: spacing.generate(4);
		align-items: center;
		max-width: units.px2rem(440px);
	}
}

.types__select-title {
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 1);
	white-space: nowrap;
}

.types__quote {
	width: 100%;
	padding: spacing.generate(6) spacing.generate(6);

	@include breakpoint.up(md) {
		width: 50%;
		padding: spacing.generate(6) spacing.generate(8);
	}
}

.types__subtitle {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 7);
	font-family: typography-settings.$font-family-secondary;

	@include breakpoint.up(lg) {
		font-size: map.get(typography-settings.$font-size-values, 9);
	}
}

.types__lister-item {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-block: spacing.generate(2);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	border-bottom: borders.$width solid map.get(colors.$grays, 600);

	&:first-of-type {
		border-top: borders.$width solid map.get(colors.$grays, 600);
	}
}

.types__lister-value {
	display: flex;
	align-items: center;
	justify-self: end;
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 2);
	text-align: right;
}

.types__lister-muted {
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 0);
}

.types__lister-link {
	@include typography.link-unstyled();
}

.types__lister-download {
	padding: units.px2rem(3px) units.px2rem(7px);
	margin-left: spacing.generate(2);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	text-decoration: none;
	color: colors.$white;
	background-color: colors.$black;
}

.types__lister-icon {
	width: units.px2rem(16px);
	height: units.px2rem(16px);
	margin-left: spacing.generate(2);
	color: colors.$blue;
}

.types__text {
	padding-block: spacing.generate(4);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 0);
	line-height: map.get(typography-settings.$line-height-values, 1);
}

.types__tooltip-text.types__tooltip-text {
	left: spacing.generate(-5);
}

.types__quote-actions {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(4);
	align-items: center;
	justify-content: center;
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 2);

	@include breakpoint.up(md) {
		justify-content: space-between;
	}
}

.types__quote-actions-text {
	white-space: nowrap;
}

.types__quote-actions-value {
	margin-left: spacing.generate(4);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
}

.types__image-wrap {
	width: 100%;

	@include breakpoint.up(md) {
		width: 50%;
	}
}

.types__image-wrap > figure,
.types__image-wrap > figure > picture {
	height: 100%;
}

.types__image {
	width: 100%;
	max-width: 100%;
	height: units.px2rem(405px);
	object-fit: cover;

	@include breakpoint.up(md) {
		height: 100%;
	}
}

.types__scroll {
	text-align: center;
}

.types__contact-image {
	position: relative;
	margin-bottom: spacing.generate(-1);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		bottom: spacing.generate(5);
		margin-top: spacing.generate(-4);
		margin-bottom: spacing.generate(-6);
	}
}
