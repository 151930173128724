@use '../settings/colors';
@use '../tools/breakpoint';
@use '../tools/units';

.splide--custom > .splide__pagination {
	bottom: units.px2rem(60px);
}

.splide--custom > .splide__pagination .splide__pagination__page {
	width: units.px2rem(40px);
	height: units.px2rem(5px);
	border-radius: 0;
	background-color: colors.$white;
	opacity: 1;
}

.splide--custom > .splide__pagination .splide__pagination__page.is-active {
	background-color: colors.$warning;
	transform: scale(1);
}

.splide--custom,
.splide--custom .splide__track,
.splide--custom .splide__slide,
.splide__slide > picture {
	height: 100%;
}

.splide__arrow--custom.splide__arrow--custom {
	width: 4em;
	height: 4em;
	background-color: transparent;
}

.splide__arrow--custom.splide__arrow--custom > svg {
	width: 3em;
	height: 3em;
}

.splide--hero .splide__pagination {
	bottom: units.px2rem(30px);

	@include breakpoint.up(md) {
		bottom: units.px2rem(40px);
	}
}

.splide__arrow--hero.splide__arrow--hero {
	display: none;
	width: units.px2rem(60px);
	height: units.px2rem(60px);
	border-radius: 0;
	opacity: 1;

	@include breakpoint.up(md) {
		display: block;
	}
}

.splide__arrow--hero.splide__arrow--hero > svg {
	width: units.px2rem(60px);
	height: units.px2rem(60px);
}

.splide--hero .splide__slide {
	overflow: hidden;
}
