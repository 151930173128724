@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.process__title {
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.process__perex {
	max-width: units.px2rem(650px);
	font-size: map.get(typography.$font-size-values, 0);
}

.process__video-banner {
	position: relative;
	display: block;
	width: 100%;
	margin-block: spacing.generate(6) spacing.generate(10);
	margin-left: 0;

	@include breakpoint.up(lg) {
		width: 100%;
		margin-left: spacing.generate(-30);
	}
}

.process__video-banner--center {
	@include breakpoint.up(lg) {
		width: 125%;
		margin-left: spacing.generate(-24);
	}
}

.process__video-banner.switch {
	display: none;
}

.process__video-img {
	width: 100%;
	height: 100%;

	@include breakpoint.up(sm) {
		width: 100%;
		height: auto;
	}
}

.process__video-btn {
	position: absolute;
	top: units.px2rem(100px);
	right: units.px2rem(60px);
	z-index: 1;
	appearance: none;
	width: units.px2rem(80px);
	height: units.px2rem(80px);
	padding: 0;
	border-color: transparent;
	background: transparent;
	cursor: pointer;

	@include breakpoint.up(sm) {
		top: units.px2rem(50px);
		right: units.px2rem(170px);
		width: units.px2rem(150px);
		height: units.px2rem(150px);
	}

	@include breakpoint.up(md) {
		top: units.px2rem(70px);
		right: units.px2rem(250px);
	}

	@include breakpoint.up(lg) {
		top: units.px2rem(100px);
		right: units.px2rem(330px);
	}

	@include breakpoint.up(xl) {
		right: units.px2rem(350px);
	}
}

.process__video-btn--full {
	inset: 0;
	width: 100%;
	height: 100%;
}

.process__video-wrapper-desktop,
.process__video-wrapper-mobile {
	display: none;
	width: 100%;
	margin-block: spacing.generate(6) spacing.generate(10);
}

.process__video-wrapper-desktop.switch,
.process__video-wrapper-mobile.switch {
	display: block;
}

.process__video-item {
	display: block;
	width: 90%;
	margin-inline: auto;

	@include breakpoint.up(md) {
		width: 80%;
	}

	@include breakpoint.up(xl) {
		width: 83%;
	}
}

.process__video-item--large {
	width: 100%;
	margin-left: spacing.generate(-10);

	@include breakpoint.up(lg) {
		width: 110%;
	}
}

.process__items {
	display: flex;
	flex-wrap: wrap;
	margin-left: spacing.generate(5);
}

.process__item-number {
	padding-inline: 0;
	margin-left: units.px2rem(-13px);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
	line-height: units.px2rem(28px);
	text-align: center;
	vertical-align: top;
	color: colors.$red;
}

.process__item-text {
	padding-bottom: spacing.generate(10);
	padding-left: spacing.generate(6);
	line-height: map.get(typography.$line-height-values, 1);
	vertical-align: top;

	@include breakpoint.up(lg) {
		padding-bottom: 0;
	}
}

.process__items-column {
	display: flex;
	flex-direction: column;
	width: units.px2rem(570px);
	padding: 0 spacing.generate(5) 0 0;
	font-size: map.get(typography.$font-size-values, -1);

	@include breakpoint.up(lg) {
		padding: spacing.generate(2) spacing.generate(5);
	}
}

.process__item {
	position: relative;
	display: flex;
	min-height: units.px2rem(160px);
}

.process__item:not(:nth-child(2n)) {
	z-index: -2;
	border-left: units.px2rem(1px) solid map.get(colors.$grays, 400);
}

.process__item::before {
	content: '';
	position: absolute;
	top: units.px2rem(-20px);
	left: spacing.generate(-7);
	z-index: -1;
	width: spacing.generate(14);
	height: spacing.generate(14);
	border: map.get(borders.$width-values, default) solid colors.$red;
	border-radius: 50%;
	background-color: colors.$white;
}

.process__item-var {
	z-index: -2;
	border-left: units.px2rem(1px) solid map.get(colors.$grays, 400);

	@include breakpoint.up(xl) {
		border-left: transparent;
	}
}

.process__item-title {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
}

.process__btn {
	width: 100%;
	margin-top: spacing.generate(5);
	text-align: center;
}
