@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.textfield {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: spacing.generate(4);

	&:focus {
		background-color: color.adjust(colors.$blue, $lightness: -10%);
		outline: 0;
	}
}

.textfield:disabled {
	color: map.get(colors.$grays, 600);
	background-color: map.get(colors.$grays, 200);
}

.textfield::placeholder {
	color: map.get(colors.$grays, 600);
}

.textfield--no-offset {
	margin-bottom: 0;
}

.textfield > label {
	position: absolute;
	top: units.px2rem(7px);
	left: units.px2rem(11px);
	font-size: map.get(typography.$font-size-values, -1);
	color: map.get(colors.$grays, 600);
}

.textfield > input {
	width: 100%;
	min-height: units.px2rem(56px);
	padding: spacing.generate(5) spacing.generate(2) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);
	border: borders.$width solid map.get(colors.$grays, 400);
	border-radius: borders.$radius;
}

.textfield--small > input {
	min-height: units.px2rem(50px);
	padding: spacing.generate(3);
}

.textfield--btn > input {
	padding-right: spacing.generate(42);
}

.textfield--password > input {
	padding-right: spacing.generate(9);
}

.textfield--search > input {
	min-height: units.px2rem(52px);
	border-color: map.get(colors.$grays, 200);
	background-color: map.get(colors.$grays, 200);
}

.textfield--highlight-border > input {
	border-color: map.get(colors.$grays, 600);
}

.textfield--price > input {
	width: units.px2rem(90px);
	min-height: units.px2rem(30px);
	padding: spacing.generate(1) spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 0);
}

.textfield > input:focus {
	border-color: colors.$blue;
	outline: 0;
}

.textfield > .is-invalid {
	border-color: colors.$red;
}

.textfield > input:disabled {
	color: map.get(colors.$grays, 600);
	background-color: map.get(colors.$grays, 200);
}

.textfield__password__toggle {
	@include transitions.default(color);

	position: absolute;
	top: spacing.generate(3);
	right: spacing.generate(2);
	width: units.px2rem(35px);
	height: units.px2rem(26px);
	padding: spacing.generate(1);
	color: map.get(colors.$grays, 600);
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.textfield__password__toggle > svg {
	width: 100%;
	height: auto;
}

.textfield__password__toggle--toggled {
	color: colors.$blue;
}
