@use 'sass:map';
@use '../settings/typography' as typography-settings;
@use '../tools/typography';

.h1 {
	@include typography.generate(heading1);
}

.h2 {
	@include typography.generate(heading2);
}

.h3 {
	@include typography.generate(heading3);
}

.text--center {
	text-align: center;
}

.text--small {
	font-size: map.get(typography-settings.$font-size-values, -1);
}
