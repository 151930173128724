@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.detail {
	display: grid;
	grid-template-areas:
		'nav'
		'lightbox'
		'info'
		'map'
		'details';
	grid-column-gap: spacing.generate(3);

	@include breakpoint.up(md) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'lightbox nav'
			'lightbox info'
			'map info'
			'map info'
			'map details';
	}
}

.detail__info {
	grid-area: info;
}

.detail__image {
	grid-area: lightbox;
}

.detail__image a > img {
	border-radius: map.get(borders.$radius-values, large);
}

.detail__map-wrap {
	position: relative;
	grid-area: map;
	touch-action: none;
}

.detail__map.detail__map {
	width: 100%;
	height: units.px2rem(260px);
	border-radius: map.get(borders.$radius-values, large);

	@include breakpoint.up(md) {
		height: units.px2rem(430px);
	}
}

.detail__map__btn.detail__map__btn {
	position: absolute;
	bottom: spacing.generate(4);
	left: 50%;
	z-index: 1000;
	white-space: nowrap;
	background: colors.$white;
	transform: translateX(-50%);

	@include breakpoint.up(md) {
		bottom: spacing.generate(6);
	}
}

.detail__nav {
	display: flex;
	justify-content: space-between;
	margin-bottom: spacing.generate(7);
}

.detail__nav__item {
	font-size: map.get(typography.$font-size-values, -1);
	letter-spacing: units.px2rem(-0.07px);
	text-decoration: none;
	color: map.get(colors.$grays, 600);
}

.detail__nav__back {
	position: relative;
	padding-left: spacing.generate(4);

	&::after,
	&::before {
		content: '';
		position: absolute;
		left: spacing.generate(1);
		width: units.px2rem(1px);
		height: units.px2rem(11px);
		background-color: map.get(colors.$grays, 600);
	}

	&::after {
		top: units.px2rem(7px);
		transform: rotate(-45deg);
	}

	&::before {
		transform: rotate(45deg);
	}
}

.detail__nav__favourite {
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
}

.detail__nav__favourite-icon {
	stroke: map.get(colors.$grays, 800);
	fill: transparent;
	stroke-width: 0.5px;
}

.detail__nav__favourite-icon.product__favourite--active {
	stroke: transparent;
	fill: colors.$blue;
}

.detail__title {
	margin-bottom: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 3);
	letter-spacing: units.px2rem(-0.24px);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
		letter-spacing: units.px2rem(-0.32px);
	}
}

.detail__location {
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 3);
		line-height: map.get(typography.$line-height-values, -2);
	}
}

.detail__subtitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
	letter-spacing: units.px2rem(-0.28px);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 3);
		letter-spacing: units.px2rem(-0.24px);
	}
}

.detail__details {
	grid-area: details;
}

.detail__ads > .product {
	width: 100%;
	max-width: unset;

	@media (min-width: units.px2rem(375px)) {
		width: 100%;
	}

	@include breakpoint.up(lg) {
		width: 100%;
		padding: 0;
	}
}

.detail__next-items {
	width: 100%;
	margin-top: spacing.generate(2);
	text-align: center;
}
