@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.contest {
	position: relative;
	overflow: hidden;
	background-color: colors.$red;

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(7);
	}

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.contest__video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contest__inner {
	position: relative;
	isolation: isolate;
	display: flex;
	flex-flow: nowrap column;

	@include breakpoint.up(sm) {
		flex-flow: nowrap row;
	}
}

.contest__hero {
	position: relative;
	width: 100%;
	margin-top: units.px2rem(200px);

	@include breakpoint.up(sm) {
		width: 50%;
		margin-top: 0;
		margin-left: units.px2rem(-70px);
	}
}

.contest__hero__img {
	max-width: 100%;
	height: auto;
}

.contest__content {
	position: absolute;
	top: spacing.generate(8);
	left: 50%;
	display: flex;
	flex-direction: column;
	gap: spacing.generate(70);
	min-width: 100%;
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		position: relative;
		top: 0;
		left: 0;
		gap: 0;
		justify-content: center;
		min-width: 57%;
		transform: translateX(0);
	}
}

.contest__title {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;
	text-align: center;
	text-transform: uppercase;
	color: colors.$white;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 11);
		text-align: right;
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 12);
	}

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 13);
	}
}

.contest__buttons {
	display: inline-flex;
	flex-direction: column;
	gap: spacing.generate(3);
	place-self: center flex-end;
	text-transform: uppercase;

	@include breakpoint.up(md) {
		flex-direction: row;
		align-self: flex-end;
		max-width: 100%;
	}

	@include breakpoint.up(lg) {
		gap: spacing.generate(8);
	}
}

.contest__bubble {
	position: relative;
	padding: spacing.generate(5);
	margin-inline: auto;
	text-align: center;
	border-radius: units.px2rem(14px);
	background-color: colors.$gray-light;

	@include breakpoint.up(md) {
		max-width: units.px2rem(600px);
		padding: spacing.generate(10);
	}

	@include breakpoint.up(lg) {
		max-width: units.px2rem(310px);
		padding: spacing.generate(5);
	}

	@include breakpoint.up(xl) {
		max-width: units.px2rem(480px);
		padding: spacing.generate(10);
	}
}

.contest__bubble::after {
	content: '';
	position: absolute;
	bottom: units.px2rem(-40px);
	left: 20%;
	width: 0;
	height: 0;
	border-top: 60px solid colors.$gray-light;
	border-left: 60px solid transparent;
}

.contest__bubble-text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 3);
	line-height: map.get(typography.$line-height-values, 1);
	color: colors.$black;

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.contest__items__image {
	position: relative;
	margin-bottom: spacing.generate(6);
	text-align: center;

	@include breakpoint.up(lg) {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-bottom: 0;
		transform: translateX(-50%);
	}
}

.contest__items {
	display: flex;
	flex-flow: column-reverse wrap;
	padding-block: spacing.generate(6);

	@include breakpoint.up(lg) {
		flex-flow: row wrap;
	}
}

.contest__item {
	width: 100%;

	@include breakpoint.up(lg) {
		position: relative;
		width: 50%;
	}
}

.contest__grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: spacing.generate(5);
	margin-inline: auto;

	@include breakpoint.up(sm) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: spacing.generate(7);
	}
}

.contest__grid__item {
	display: flex;
	flex-direction: column;
	width: units.px2rem(275px);
	margin-inline: auto;
	overflow: hidden;
	border-radius: map.get(borders.$radius-values, large);
	background-color: colors.$gray-light;
}

.contest__grid__content {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: spacing.generate(5);
}

.contest__grid__content > p {
	padding-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
}

.contest__grid__image {
	width: 100%;
	max-width: 100%;
	height: units.px2rem(185px);
	object-fit: cover;
	transition: transform 0.3s ease;

	@include breakpoint.up(md) {
		height: units.px2rem(165px);
	}

	&:hover {
		transform: scale(1.1);
	}
}

.contest__form__wrap {
	position: relative;
	padding-bottom: spacing.generate(8);
	background-color: colors.$gray-light;
}

.contest__form__title {
	padding-top: spacing.generate(12);
	text-align: center;
	text-transform: uppercase;
	scroll-margin-top: 100px;

	@include breakpoint.up(sm) {
		padding-top: spacing.generate(14);
	}
}

.contest__form-svg {
	position: absolute;
	top: units.px2rem(-5px);
	left: 50%;
	transform: translateX(-50%);
}

.contest__form {
	padding: spacing.generate(2);

	@include breakpoint.up(sm) {
		padding: spacing.generate(2) spacing.generate(7);
	}
}
