@use 'sass:map';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';

.howit__header {
	width: 100%;
	margin-top: spacing.generate(5);
	text-align: left;

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(14);
		text-align: center;
	}
}

.howit__title {
	font-size: map.get(typography.$font-size-values, 3);

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 10);
	}
}
