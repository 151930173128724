@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';
@use '../tools/units';

.availability {
	padding: spacing.generate(5) spacing.generate(4) spacing.generate(3);
	margin-block: spacing.generate(5) spacing.generate(15);
	border-radius: map.get(borders.$radius-values, large);
	background-color: map.get(colors.$grays, 200);
}

.availability__title {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
}

.availability__wrap {
	position: relative;
	padding-right: spacing.generate(2);
	margin-bottom: spacing.generate(19);
}

.availability__list {
	display: flex;
	flex-direction: row;
	gap: spacing.generate(2);
	overflow: scroll hidden;
	scroll-snap-type: both mandatory;
}

.availability__list::-webkit-scrollbar {
	display: none;
}

.availability__list--offset {
	padding-left: spacing.generate(2);
}

.availability__list-wrap {
	position: absolute;
	width: 100%;
	padding-right: spacing.generate(2);
	overflow: hidden;
}

.availability__list-wrap:last-of-type {
	padding-right: 0;
}

.availability__item-wrap {
	position: relative;
	text-align: center;
}

.availability__list.is-drag {
	cursor: grab;
}

.availability__list.is-drag > .availability__item-wrap {
	pointer-events: none;
}

.availability__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: units.px2rem(100px);
	padding: spacing.generate(2) spacing.generate(3);
	border: borders.$width solid colors.$green-lighter;
	border-radius: map.get(borders.$radius-values, large);
	background-color: colors.$green-lighter;
	cursor: pointer;
}

.availability__item--sold {
	border-color: colors.$red-light;
	background-color: colors.$red-light;
}

.availability__item--demand {
	border-color: colors.$warning-light;
	background-color: colors.$warning-light;
}

.availability__item--half-right,
.availability__item--half-left {
	min-width: unset;
}

.availability__item--half-right {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.availability__item--half-left {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.availability__input:checked ~ .availability__item {
	color: colors.$white;
	background-color: map.get(colors.$grays, 800);
}

.availability__input:disabled ~ .availability__item {
	cursor: not-allowed;
}

.availability__item__title {
	margin-bottom: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
	white-space: nowrap;
}

.availability__item__value {
	font-size: map.get(typography.$font-size-values, -2);
	white-space: nowrap;
}

.availability__item__value--discount {
	display: block;
	margin-top: spacing.generate(1);
	text-align: center;
	text-decoration: line-through;
}

.availability__arrow {
	position: absolute;
	top: 0;
	z-index: 100;
	display: block;
	width: units.px2rem(25px);
	height: 100%;
	padding: units.px2rem(45px) units.px2rem(5px);
	background-color: map.get(colors.$grays, 200);

	&::after,
	&::before {
		content: '';
		position: absolute;
		left: units.px2rem(12px);
		width: units.px2rem(2px);
		height: units.px2rem(16px);
		background-color: map.get(colors.$grays, 800);
	}

	&::after {
		top: units.px2rem(42px);
		transform: rotate(-45deg);
	}

	&::before {
		top: units.px2rem(32px);
		transform: rotate(45deg);
	}
}

.availability__arrow--prev {
	left: units.px2rem(-20px);
	cursor: pointer;
}

.availability__arrow--next {
	right: units.px2rem(-20px);
	cursor: pointer;

	&::after {
		transform: rotate(45deg);
	}

	&::before {
		transform: rotate(-45deg);
	}
}

.availability__info {
	padding-top: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, -1);
}

.availability__year {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	white-space: nowrap;
}

.availability__year--gap {
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: units.px2rem(-6px);
		height: 100%;
		border: borders.$width solid map.get(colors.$grays, 600);
	}
}

.availability__label-wrap {
	position: relative;
	display: flex;
	flex-direction: row;
	gap: units.px2rem(2px);
}
