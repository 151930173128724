@use 'sass:map';
@use '../tools/breakpoint';
@use '../tools/spacing';

.product-list {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	width: calc(100% + #{spacing.generate(2)});
	margin: spacing.generate(-2) spacing.generate(-1);

	@include breakpoint.up(lg) {
		width: calc(100% + #{spacing.generate(4)});
		margin: spacing.generate(-2);
	}
}

.product-list__button-wrap {
	width: 100%;
	margin-top: spacing.generate(15);
	text-align: center;
}
