@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.is-invalid > label {
	color: orange;
}

.is-valid > label {
	color: green;
}

/* stylelint-disable selector-max-specificity */
.form-group.required > label::after,
.form-group.required > .form-check:first-child > label::after {
	content: '\00a0*';
	color: orange;
}

.form__title {
	font-size: map.get(typography.$font-size-values, 1);
	letter-spacing: units.px2rem(-0.24px);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 3);
	}
}

.form__title--large {
	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.form__subtitle {
	font-size: map.get(typography.$font-size-values, 1);
}

.form__text {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, -1);
}

.form__link {
	display: inline-block;
	font-size: map.get(typography.$font-size-values, -1);
	text-decoration: underline;
	color: map.get(colors.$grays, 800);
}

.form__link-inverse {
	display: inline-block;
	margin-block: spacing.generate(2) spacing.generate(6);
	font-size: map.get(typography.$font-size-values, -2);
	text-decoration: underline;
	color: map.get(colors.$grays, 600);
}

.form__row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: spacing.generate(4);
}

.form__row-large {
	grid-template-columns: 1fr;

	@include breakpoint.up(lg) {
		grid-template-columns: 1fr 1fr;
	}
}

.form__row--unequal {
	grid-template-columns: 1fr 3fr;
}

.form__footer {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(3);
	margin-bottom: spacing.generate(4);
}

.form__error {
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, -1);
	text-align: right;
	color: colors.$red;
}
