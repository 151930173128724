@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography' as typography-settings;
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/typography';
@use '../tools/units';

.choices__item--selectable.is-highlighted {
	background-color: colors.$blue;
}

.choices {
	position: relative;
	width: 100%;
	margin-top: spacing.generate(3);
	margin-bottom: spacing.generate(5);
	overflow: hidden;
	font-size: map.get(typography-settings.$font-size-values, 0);

	@include breakpoint.up(sm) {
		margin-top: 0;
	}
}

.choices:focus {
	outline: 0;
}

.choices:last-child {
	margin-bottom: 0;
}

.choices.is-open {
	overflow: visible;
}

.choices [hidden] {
	display: none;
}

.choices[data-type*='select-one'] {
	cursor: pointer;
}

.choices[data-type*='select-one']::after {
	content: '';
	position: absolute;
	top: 50%;
	right: spacing.generate(3);
	width: 0;
	height: 0;
	margin-top: units.px2rem(-2px);
	border-width: units.px2rem(5px);
	border-style: solid;
	border-color: map.get(colors.$grays, 800) transparent transparent;
	pointer-events: none;
}

.choices__input {
	display: inline-block;
	max-width: 100%;
	padding: units.px2rem(4px) 0 units.px2rem(4px) units.px2rem(2px);
	font-size: map.get(typography-settings.$font-size-values, -1);
	vertical-align: baseline;
	border: 0;
	border-radius: 0;
	background-color: colors.$white;
}

.choices__input::-webkit-search-cancel-button,
.choices__input::-webkit-search-decoration,
.choices__input::-webkit-search-results-button,
.choices__input::-webkit-search-results-decoration {
	display: none;
}

.choices__input:focus {
	outline: 0;
}

.choices__button {
	text-indent: -9999px;
	appearance: none;
	border: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-color: transparent;
	cursor: pointer;
}

.choices__button:focus {
	outline: 0;
}

.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
	position: relative;
	display: inline-block;
	width: units.px2rem(8px);
	padding-left: units.px2rem(16px);
	margin: units.px2rem(4px) 0 units.px2rem(4px);
	line-height: 1;
	border-radius: 0;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==');
	background-size: units.px2rem(8px);
	opacity: 0.75;
}

.choices[data-type*='select-multiple'] .choices__button:focus,
.choices[data-type*='select-multiple'] .choices__button:hover,
.choices[data-type*='text'] .choices__button:focus,
.choices[data-type*='text'] .choices__button:hover {
	opacity: 1;
}

.choices__inner {
	display: inline-block;
	width: 100%;
	min-height: units.px2rem(35px);
	overflow: hidden;
	font-size: map.get(typography-settings.$font-size-values, 1);
	vertical-align: top;
	border: units.px2rem(1px) solid map.get(colors.$grays, 500);
	background-color: colors.$white;
}

.is-focused .choices__inner,
.is-open .choices__inner {
	border-color: map.get(colors.$grays, 500);
}

.is-open .choices__inner {
	border-radius: 0;
}

.is-flipped.is-open .choices__inner {
	border-radius: 0;
}

.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
	min-height: units.px2rem(52px);
	cursor: text;
}

.choices__list {
	padding-left: 0;
	margin: 0;
	list-style: none;
}

.choices__item--selectable {
	cursor: pointer;
}

.choices__list--multiple {
	display: inline;
}

.choices__list--multiple .choices__item {
	display: inline-block;
	padding: spacing.generate(1) spacing.generate(2);
	margin: units.px2rem(4px);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, -2);
	color: colors.$white;
	border: units.px2rem(1px) solid colors.$blue;
	background-color: colors.$blue;
}

.choices__list--single {
	padding: units.px2rem(1px);
}

.choices__list--single .choices__item {
	display: inline-block;
	padding: spacing.generate(1) spacing.generate(1);
	margin: units.px2rem(4px);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 1);
}

.choices__list--multiple .choices__item[data-deletable] {
	padding-right: spacing.generate(1);
}

.choices__list--multiple .choices__item.is-highlighted {
	border: units.px2rem(1px) solid colors.$blue-dark;
	background-color: colors.$blue-dark;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
	position: relative;
	padding: spacing.generate(2);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 1);
}

.is-disabled .choices__list--multiple .choices__item {
	border: units.px2rem(1px) solid map.get(colors.$grays, 700);
	background-color: map.get(colors.$grays, 600);
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
	position: absolute;
	top: 100%;
	z-index: 1000;
	width: 100%;
	margin-top: units.px2rem(-2px);
	overflow: hidden;
	word-break: break-all;
	border: units.px2rem(1px) solid map.get(colors.$grays, 500);
	background-color: colors.$white;
	visibility: hidden;
}

.is-active.choices__list--dropdown,
.is-active.choices__list[aria-expanded] {
	visibility: visible;
}

.is-open .choices__list--dropdown,
.is-open .choices__list[aria-expanded] {
	border-color: map.get(colors.$grays, 600);
}

.is-flipped .choices__list--dropdown,
.is-flipped .choices__list[aria-expanded] {
	top: auto;
	bottom: 100%;
	margin-top: units.px2rem(-2px);
}

.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
	position: relative;
	max-height: units.px2rem(300px);
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	will-change: scroll-position;
}

@media (width >= 640px) {
	.choices__list--dropdown .choices__item--selectable,
	.choices__list[aria-expanded] .choices__item--selectable {
		border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 200);
	}
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
	color: colors.$white;
	background-color: colors.$blue;
}

.choices__heading {
	padding: spacing.generate(2);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, -2);
	color: map.get(colors.$grays, 700);
	border-bottom: units.px2rem(1px) solid map.get(colors.$grays, 300);
}

.choices__placeholder {
	opacity: 0.5;
}

.choices__list--dropdown:has(.has-no-choices) {
	display: none;
}
