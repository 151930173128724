@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.lister {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: spacing.generate(6);
	margin-block: spacing.generate(4);
}

.lister__item {
	padding-block: spacing.generate(2);
	border-bottom: borders.$width solid map.get(colors.$grays, 600);
}

.lister__item--grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.lister__item--head {
	border-top: 0;
}

.lister__title,
.lister__item__text,
.lister__item__value {
	font-size: map.get(typography.$font-size-values, -2);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, -1);
	}
}

.lister__title {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
}

.lister__item__text {
	color: map.get(colors.$grays, 600);
}

.lister__item__value {
	display: flex;
	justify-self: end;
	font-weight: map.get(typography.$font-weight-values, medium);
	text-align: right;
}

.lister__item__icon {
	width: units.px2rem(16px);
	height: units.px2rem(16px);
	margin-right: spacing.generate(1);
}
