@use 'sass:map';
@use '../settings/colors';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';
@use '../settings/typography';

.offer__contact {
	background-color: map.get(colors.$grays, 200);
}

.offer__contact-inner {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	max-width: units.px2rem(1200px);
	padding: 0 spacing.generate(3);
	margin-inline: auto;

	@include breakpoint.up(lg) {
		flex-direction: row;
		padding: 0 spacing.generate(5);
	}
}

.offer__contact-image {
	margin-bottom: spacing.generate(-1);
}

.offer__contact-image > svg {
	width: units.px2rem(380px);
	height: auto;

	@include breakpoint.up(md) {
		width: units.px2rem(650px);
	}
}

.offer__contact-wrap {
	padding: spacing.generate(7) 0;
	text-align: center;

	@include breakpoint.up(sm) {
		padding: spacing.generate(7) spacing.generate(10);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(7) 0 spacing.generate(4) spacing.generate(8);
	}
}

.offer__contact-title {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
}

.offer__contact-text {
	margin-bottom: spacing.generate(5);
}
