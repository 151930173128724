@use '../tools/breakpoint';
@use '../tools/spacing';

.grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-inline: spacing.generate(-3);
}

.grid__item {
	width: 100%;
	padding-inline: spacing.generate(3);

	@include breakpoint.up(sm) {
		width: calc(100% / 2);
	}

	@include breakpoint.up(lg) {
		width: calc(100% / 3);
	}
}

.grid__item--half {
	@include breakpoint.up(lg) {
		width: calc(100% / 2);
	}
}
