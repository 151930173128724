@use '../tools/units';

html {
	text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

body {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-block: 0;
	font-weight: inherit;
	font-size: units.px2rem(16px);
	line-height: inherit;
}

p,
ul,
ol,
dl {
	margin-block: 0;
}

figure,
picture {
	display: block;
	margin: 0;
}

img {
	vertical-align: middle;
}
