@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/header';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.editor p {
	margin-bottom: spacing.generate(6);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(8);
	}
}

.editor ul {
	position: relative;
}

.editor__list > li {
	position: relative;
	padding: spacing.generate(1) 0 spacing.generate(1) spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 0);

	&::before {
		content: '';
		position: absolute;
		top: spacing.generate(3);
		left: 0;
		width: units.px2rem(4px);
		height: units.px2rem(4px);
		border-radius: 50%;
		background-color: colors.$black;
	}
}

.editor__list--large-text > li {
	font-size: map.get(typography.$font-size-values, 3);
}

.editor__list--bordered {
	position: relative;
	margin-block: spacing.generate(15);

	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		max-width: units.px2rem(570px);
		height: units.px2rem(1px);
		background: map.get(colors.$grays, 600);
	}

	&::before {
		top: spacing.generate(-8);
	}

	&::after {
		bottom: spacing.generate(-8);
	}
}

.editor__list-check li {
	position: relative;
	padding-left: spacing.generate(6);
	margin-block: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	color: colors.$green-light;

	&::after,
	&::before {
		content: '';
		position: absolute;
	}

	&::before {
		left: 0;
		width: units.px2rem(18px);
		height: units.px2rem(18px);
		border-radius: 50%;
		background-color: colors.$green-light;
	}

	&::after {
		top: units.px2rem(5px);
		left: units.px2rem(5px);
		width: units.px2rem(9px);
		height: units.px2rem(6px);
		border-bottom: units.px2rem(2px) solid colors.$white;
		border-left: units.px2rem(2px) solid colors.$white;
		border-radius: borders.$width;
		transform: rotate(-45deg);
	}
}

.editor__graybox {
	padding: spacing.generate(5) spacing.generate(6);
	margin-bottom: spacing.generate(10);
	background-color: map.get(colors.$grays, 200);
}

.editor__graybox > p:last-of-type {
	margin-bottom: spacing.generate(4);
}

.editor__graybox--step {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		bottom: units.px2rem(-50px);
		left: 50%;
		width: units.px2rem(1px);
		height: units.px2rem(50px);
		background-color: map.get(colors.$grays, 600);
		transform: translateX(-50%);
	}
}

.editor .strong {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 1);
}

.editor h2 {
	font-size: map.get(typography.$font-size-values, 3);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.editor h3 {
	font-size: map.get(typography.$font-size-values, 3);
}

.editor figure > figcaption {
	margin-top: spacing.generate(3);
}

.editor figure > .small {
	font-size: map.get(typography.$font-size-values, -4);
}

.editor__title-decorated {
	position: relative;
	padding-left: spacing.generate(4);

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: units.px2rem(6px);
		height: units.px2rem(6px);
		border-radius: 50%;
		background-color: colors.$black;
		transform: translateY(-50%);
	}
}

.editor__highlight {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 1);
	color: colors.$green-light;
}

.editor__highlight-link {
	text-decoration-color: colors.$green-light;
	color: colors.$green-light;
}

.editor__text-green {
	color: colors.$green-light;
}

.editor__grid {
	display: grid;
	grid-template-rows: 1fr 1fr;
	gap: spacing.generate(2);

	@include breakpoint.up(sm) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: none;
	}
}

.editor__grid--three {
	grid-template-rows: 1fr 1fr 1fr;

	@include breakpoint.up(sm) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: none;
	}
}

.editor__anchor-offset {
	padding-top: header.$height;
	margin-top: -1 * header.$height;
}

.editor__hero {
	position: relative;

	@include breakpoint.up(md) {
		margin-right: spacing.generate(14);
	}
}

.editor__hero > svg {
	position: relative;
	z-index: 20;
	width: 100%;
	height: units.px2rem(240px);
}

.editor__hero-sticker {
	display: none;

	@include breakpoint.up(md) {
		position: absolute;
		top: 50%;
		right: spacing.generate(-11);
		z-index: 10;
		display: block;
		align-self: center;
		transform: translateY(-50%);
	}
}

.editor__hero-sticker > svg {
	width: units.px2rem(150px);
	height: units.px2rem(150px);
}

.editor__hero-sticker__text {
	position: absolute;
	top: 53%;
	left: 48%;
	width: 100%;
	max-width: 8ch;
	font-weight: map.get(typography.$font-weight-values, extrabold);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;
	text-align: center;
	color: colors.$black;
	transform: translate(-50%, -50%) rotate(16deg);
}
