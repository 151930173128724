@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography' as typography-settings;
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/typography';
@use '../tools/transitions';
@use '../tools/units';

.reference-detail {
	position: relative;
	display: grid;
	grid-template-areas:
		'nav'
		'header'
		'content';
	max-width: units.px2rem(1440px);
	margin-inline: auto;

	@include breakpoint.up(md) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'nav nav'
			'header header'
			'content content';
	}

	@include breakpoint.up(lg) {
		grid-template-areas:
			'nav empty'
			'header header'
			'content content';
	}
}

.reference-detail__nav {
	display: flex;
	grid-area: nav;
	justify-content: space-between;
	padding-inline: spacing.generate(3);
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(lg) {
		padding-inline: 0;
	}
}

.reference-detail__nav-item {
	display: inline-flex;
	align-items: center;
	font-size: 0;
	text-decoration: none;
	color: colors.$black;

	@include breakpoint.up(md) {
		font-weight: map.get(typography-settings.$font-weight-values, medium);
		font-size: map.get(typography-settings.$font-size-values, 1);
	}
}

.reference-detail__nav-item--close {
	position: absolute;
	right: 50%;
	transform: translateX(50%);

	@include breakpoint.up(md) {
		right: units.px2rem(15px);
		transform: translateY(0);
	}

	@include breakpoint.up(lg) {
		right: 0;
	}
}

.reference-detail__nav-item--next {
	@include breakpoint.up(md) {
		padding-right: units.px2rem(55px);
	}

	@include breakpoint.up(lg) {
		padding-right: 0;
	}
}

.reference-detail__nav-icon {
	@include transitions.default('background-color');

	width: units.px2rem(40px);
	height: units.px2rem(42px);
	border-radius: units.px2rem(6px);
	background-color: colors.$black;
}

.reference-detail__nav-item:hover > .reference-detail__nav-icon {
	background-color: colors.$red;
}

.reference-detail__nav-icon--close {
	position: relative;

	&::after,
	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(3px);
		right: spacing.generate(4);
		width: units.px2rem(1px);
		height: units.px2rem(35px);
		background-color: colors.$white;
	}

	&::after {
		transform: rotate(-45deg);
	}

	&::before {
		transform: rotate(45deg);
	}
}

.reference-detail__nav-icon--arrow {
	position: relative;
	margin-inline: spacing.generate(3) 0;

	&::after,
	&::before {
		content: '';
		position: absolute;
		right: spacing.generate(4);
		width: units.px2rem(1px);
		height: units.px2rem(16px);
		background-color: colors.$white;
	}

	&::after {
		top: units.px2rem(18px);
		transform: rotate(45deg);
	}

	&::before {
		top: units.px2rem(7px);
		transform: rotate(-45deg);
	}
}

.reference-detail__nav-icon--arrow-prev {
	margin-inline: 0 spacing.generate(3);
	transform: rotate(180deg);
}

.reference-detail__header {
	display: grid;
	grid-area: header;
	grid-template-areas:
		'slider'
		'quote';
	margin-bottom: spacing.generate(8);

	@include breakpoint.up(md) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 'slider quote';
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(12);
	}
}

.reference-detail__slider {
	grid-area: slider;
}

.reference-detail__image {
	width: 100%;
	max-width: 100%;
	height: units.px2rem(405px);
	object-fit: cover;

	@include breakpoint.up(md) {
		height: 100%;
	}
}

.reference-detail__quote {
	grid-area: quote;
	padding: spacing.generate(8) spacing.generate(3);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(md) {
		padding: spacing.generate(8) spacing.generate(5);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(9) spacing.generate(12);
	}
}

.reference-detail__quote-inner {
	@include breakpoint.up(lg) {
		max-width: units.px2rem(525px);
	}
}

.reference-detail__quote-title {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 7);
	font-family: typography-settings.$font-family-secondary;

	@include breakpoint.up(lg) {
		font-size: map.get(typography-settings.$font-size-values, 9);
	}
}

.reference-detail__quote-text,
.reference-detail__quote-signature,
.reference-detail__info-text,
.reference-detail__results-text,
.reference-detail__about-text,
.reference__contact-text,
.reference__intro-text {
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 0);
	line-height: map.get(typography-settings.$line-height-values, 1);

	@include breakpoint.up(lg) {
		font-size: map.get(typography-settings.$font-size-values, 2);
	}
}

.reference-detail__quote-signature {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(1);
}

.reference-detail__content {
	display: grid;
	grid-area: content;
	grid-template-areas:
		'info'
		'results'
		'about'
		'references';
	row-gap: spacing.generate(8);

	@include breakpoint.up(md) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'info results'
			'about about'
			'references references';
		row-gap: spacing.generate(4);
	}
}

.reference-detail__info {
	grid-area: info;
}

.reference-detail__info-title {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 7);
	font-family: typography-settings.$font-family-secondary;

	@include breakpoint.up(lg) {
		padding-left: spacing.generate(8);
		font-size: map.get(typography-settings.$font-size-values, 9);
	}
}

.reference-detail__info-text {
	position: relative;
	padding: spacing.generate(6) spacing.generate(6) spacing.generate(5) spacing.generate(5);
	border-radius: units.px2rem(25px);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(md) {
		padding: spacing.generate(5) spacing.generate(4) spacing.generate(5) spacing.generate(5);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(5) spacing.generate(5) spacing.generate(8) spacing.generate(8);
	}
}

.reference-detail__info-text-quote {
	position: absolute;
	right: units.px2rem(60px);
	bottom: units.px2rem(-50px);
	z-index: -1;
}

.reference-detail__info-image {
	position: relative;
	padding-top: spacing.generate(10);
	text-align: center;

	@include breakpoint.up(lg) {
		bottom: units.px2rem(25px);
		padding-top: 0;
	}
}

.reference-detail__info-image > svg {
	width: units.px2rem(330px);
	height: auto;

	@include breakpoint.up(lg) {
		width: units.px2rem(365px);
	}
}

.reference-detail__results {
	position: relative;
	grid-area: results;
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(md) {
		padding-left: spacing.generate(5);
		margin-bottom: 0;
	}

	@include breakpoint.up(lg) {
		padding-left: spacing.generate(12);
		margin-bottom: spacing.generate(5);
	}
}

.reference-detail__results-title {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 7);
	font-family: typography-settings.$font-family-secondary;

	@include breakpoint.up(lg) {
		font-size: map.get(typography-settings.$font-size-values, 9);
	}
}

.reference-detail__results-image {
	position: relative;
	left: 50%;
	display: inline-block;
	margin-top: spacing.generate(4);
	transform: translateX(-50%);

	@include breakpoint.up(md) {
		position: absolute;
		bottom: spacing.generate(5);
	}
}

.reference-detail__results-image > svg {
	width: units.px2rem(220px);
	height: auto;
}

.reference-detail__results-image-text {
	position: absolute;
	top: units.px2rem(17px);
	left: 50%;
	font-size: map.get(typography-settings.$font-size-values, 9);
	font-family: typography-settings.$font-family-secondary;
	transform: translateX(-50%);
}

.reference-detail__results-source {
	width: 100%;
	margin-top: spacing.generate(5);
	font-size: map.get(typography-settings.$font-size-values, -3);
	text-align: center;
	color: map.get(colors.$grays, 500);

	@include breakpoint.up(md) {
		position: absolute;
		bottom: 0;
		width: 80%;
	}
}

.reference-detail__results-source > a {
	color: map.get(colors.$grays, 500);
}

.reference-detail__about {
	grid-area: about;
	padding: spacing.generate(7) spacing.generate(4);
	text-align: center;
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(lg) {
		padding: spacing.generate(8) spacing.generate(5);
	}
}

.reference-detail__about-title {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 7);
	font-family: typography-settings.$font-family-secondary;

	@include breakpoint.up(lg) {
		font-size: map.get(typography-settings.$font-size-values, 9);
	}
}

.reference-detail__about-text {
	max-width: units.px2rem(1020px);
	margin-inline: auto;
	margin-bottom: spacing.generate(5);
}

.reference-detail__about-logo > svg {
	width: units.px2rem(191px);
	height: auto;
}

.reference__intro {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include breakpoint.up(md) {
		flex-direction: row;
	}

	@include breakpoint.up(lg) {
		gap: spacing.generate(6);
	}
}

.reference__intro-image {
	flex: 1;
	margin-top: spacing.generate(4);
	text-align: center;

	@include breakpoint.up(md) {
		margin-top: spacing.generate(20);
		text-align: right;
	}

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.reference__intro-image > svg {
	width: units.px2rem(345px);
	height: auto;

	@include breakpoint.up(lg) {
		width: units.px2rem(490px);
	}
}

.reference__intro-content {
	display: flex;
	flex: 1;
	flex-direction: column;

	@include breakpoint.up(md) {
		max-width: units.px2rem(560px);
	}
}

.reference__intro-title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 11);
	line-height: map.get(typography-settings.$line-height-values, -2);
	font-family: typography-settings.$font-family-secondary;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(7);
		font-size: map.get(typography-settings.$font-size-values, 12);
	}
}

.reference__nav {
	padding-top: spacing.generate(6);

	@include breakpoint.up(lg) {
		padding-top: spacing.generate(4);
	}
}

.reference__nav-detail {
	grid-area: references;
	padding-block: 0 spacing.generate(2);
	text-align: center;

	@include breakpoint.up(md) {
		padding-block: 0;
	}

	@include breakpoint.up(lg) {
		padding-block: spacing.generate(4) spacing.generate(5);
	}
}

.reference__nav-title,
.reference__nav-detail-title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 4);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(10);
		font-size: map.get(typography-settings.$font-size-values, 5);
		text-align: left;
	}
}

.reference__nav-title {
	margin-bottom: spacing.generate(5);
}

.reference__nav-line {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(4);
	align-items: center;
	justify-content: center;
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(md) {
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: spacing.generate(6);
	}

	@include breakpoint.up(lg) {
		gap: spacing.generate(8);
	}
}

.reference__nav-line-detail {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: units.px2rem(20px);
	align-items: center;
	justify-content: center;
	text-align: center;

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: units.px2rem(50px);
		align-items: flex-start;
	}

	@include breakpoint.up(lg) {
		gap: units.px2rem(40px);
	}
}

.reference__nav-item {
	width: units.px2rem(305px);
	text-align: center;
	text-decoration: none;
	color: colors.$black;

	@include breakpoint.up(md) {
		width: calc(33.33% - #{units.px2rem(20px)});
	}

	@include breakpoint.up(lg) {
		width: calc(33.33% - #{units.px2rem(40px)});
	}
}

.reference__nav-item-detail {
	max-width: units.px2rem(305px);
	text-align: center;
	text-decoration: none;
	color: colors.$black;

	@include breakpoint.up(md) {
		min-width: units.px2rem(210px);
		max-width: unset;
	}
}

.reference__nav-figure-wrap {
	position: relative;
}

.reference__nav-figure {
	position: relative;
	display: block;
	overflow: hidden;
	border-radius: units.px2rem(24px);
}

.reference__nav-figure--secondary {
	display: none;
}

.reference__nav-image {
	width: 100%;
	height: auto;
}

.reference__nav-item:hover > .reference__nav-figure-wrap > .reference__nav-figure {
	display: none;
}

.reference__nav-item:hover > .reference__nav-figure-wrap > .reference__nav-figure--secondary {
	display: block;
}

.reference__nav-text {
	padding-top: spacing.generate(3);
	font-size: map.get(typography-settings.$font-size-values, 0);
	line-height: map.get(typography-settings.$line-height-values, 1);

	@include breakpoint.up(md) {
		padding-top: spacing.generate(5);
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography-settings.$font-size-values, 2);
	}
}

.reference__contact {
	background-color: map.get(colors.$grays, 200);
}

.reference__contact-inner {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	max-width: units.px2rem(1005px);
	padding: 0 spacing.generate(3);
	margin-inline: auto;

	@include breakpoint.up(md) {
		flex-direction: row;
		padding: 0 spacing.generate(5);
	}
}

.reference__contact-image {
	position: relative;
	margin-bottom: spacing.generate(-1);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		bottom: spacing.generate(5);
		margin-top: 0;
		margin-bottom: spacing.generate(-6);
	}
}

.reference__contact-image > svg {
	width: units.px2rem(336px);
	height: auto;
}

.reference__contact-wrap {
	padding: spacing.generate(7) 0;
	text-align: center;

	@include breakpoint.up(sm) {
		padding: spacing.generate(7) spacing.generate(3);
	}

	@include breakpoint.up(md) {
		padding: spacing.generate(7) 0 spacing.generate(4) spacing.generate(8);
	}
}

.reference__contact-title {
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 4);
}

.reference__contact-text {
	margin-bottom: spacing.generate(5);
}

.reference__slider-wrap {
	position: relative;

	@include breakpoint.up(md) {
		padding-inline: spacing.generate(7);
	}
}

.reference__slider__list-wrap {
	overflow: hidden;
}

.reference__slider__list {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(8);
	align-items: center;

	@include breakpoint.up(md) {
		flex-direction: row;
		align-items: flex-start;
		overflow: scroll hidden;
		scroll-snap-type: both mandatory;
	}
}

.reference__slider__list::-webkit-scrollbar {
	display: none;
}

.reference__slider__arrow {
	visibility: hidden;

	@include breakpoint.up(md) {
		position: absolute;
		top: 30%;
		width: units.px2rem(40px);
		height: units.px2rem(40px);
		visibility: visible;
	}

	&::after,
	&::before {
		content: '';
		position: absolute;
		left: units.px2rem(12px);
		width: units.px2rem(3px);
		height: units.px2rem(24px);
		background-color: map.get(colors.$grays, 800);
	}

	&::before {
		top: 0;
		left: 50%;
		border-top-left-radius: units.px2rem(3px);
		border-top-right-radius: units.px2rem(3px);
		transform: rotate(45deg) translateX(-50%);
	}

	&::after {
		top: units.px2rem(15px);
		left: 50%;
		border-bottom-right-radius: units.px2rem(3px);
		border-bottom-left-radius: units.px2rem(3px);
		transform: rotate(-45deg) translateX(-50%);
	}
}

.reference__slider__arrow--prev {
	left: units.px2rem(-8px);
	cursor: pointer;

	&::before {
		top: 2px;
	}
}

.reference__slider__arrow--next {
	right: units.px2rem(-8px);
	cursor: pointer;

	&::after {
		transform: rotate(45deg);
	}

	&::before {
		transform: rotate(-45deg);
	}
}

.reference__show-more {
	display: block;

	@include breakpoint.up(md) {
		display: none;
	}
}
