@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';

.price-info {
	display: flex;
	flex-flow: column	wrap;
	align-items: baseline;

	@include breakpoint.up(sm) {
		flex-direction: row;
	}

	@include breakpoint.up(md) {
		flex-direction: column;
	}

	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.price-info__text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 0);
}

.price-info__text:last-of-type {
	@include breakpoint.up(sm) {
		margin-left: auto;
	}

	@include breakpoint.up(md) {
		margin-left: initial;
	}

	@include breakpoint.up(lg) {
		margin-left: auto;
	}
}

.price-info__price {
	padding-block: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 3);
	line-height: map.get(typography.$line-height-values, 0);
	white-space: nowrap;
	color: colors.$blue;

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(1);
	}

	@include breakpoint.up(md) {
		padding-inline: 0;
		padding-block: spacing.generate(1);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(1);
	}
}
