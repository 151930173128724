@use 'sass:map';
@use '../settings/breakpoints';

@mixin up($breakpoint, $retina: false) {
	@if (not map.has-key(breakpoints.$values, $breakpoint)) or $breakpoint == 'xxs' {
		@if $retina {
			@media (resolution >= 192dpi) {
				@content;
			}
		} @else {
			@content;
		}
	} @else {
		@if $retina {
			@media (resolution >= 192dpi) and (width >= #{map.get(breakpoints.$values, $breakpoint)}) {
				@content;
			}
		} @else {
			@media (width >= #{map.get(breakpoints.$values, $breakpoint)}) {
				@content;
			}
		}
	}
}
