@use 'sass:map';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.explore,
.explore__figure {
	position: relative;
	height: units.px2rem(328px);

	@include breakpoint.up(sm) {
		height: auto;
	}
}

.explore {
	margin-inline: spacing.generate(3);
	margin-bottom: spacing.generate(14);
}

.explore__figure {
	max-width: units.px2rem(1170px);
}

.explore__img {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		position: relative;
		max-width: 100%;
	}
}

.explore__button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
