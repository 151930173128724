@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/header';
@use '../settings/shadows';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.header {
	position: relative;
	z-index: 1000;
	height: header.$height;
}

.header__inner {
	position: fixed;
	width: 100%;
	height: header.$height;
	background-color: colors.$white;

	@include breakpoint.up(md) {
		height: header.$height-md;
	}

	@include breakpoint.up(lg) {
		height: header.$height;
		margin-bottom: 0;
	}
}

.header__container {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-flow: column;
	align-items: center;
	height: 100%;

	@include breakpoint.up(sm) {
		display: flex;
		flex-wrap: wrap;
		padding-top: spacing.generate(2);
	}

	@include breakpoint.up(lg) {
		flex-wrap: nowrap;
		padding-block: 0;
	}
}

.header__logo {
	order: 1;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
	font-family: typography.$font-family-secondary;
	text-transform: uppercase;
	color: colors.$black;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
		line-height: map.get(typography.$line-height-values, -1);
	}
}

.header__nav--primary {
	@include transitions.default('opacity, visibility');

	position: absolute;
	top: header.$height;
	right: 0;
	left: 0;
	z-index: 200;
	display: block;
	width: 100%;
	height: calc(100vh - #{header.$height});
	padding: spacing.generate(3);
	overflow-y: auto;
	background-color: colors.$white;
	box-shadow: shadows.$default;
	visibility: hidden;
	opacity: 0;

	@include breakpoint.up(sm) {
		position: relative;
		top: unset;
		right: unset;
		left: unset;
		order: 3;
		height: auto;
		padding: 0;
		overflow: visible;
		white-space: nowrap;
		box-shadow: none;
		visibility: visible;
		opacity: 1;
	}

	@include breakpoint.up(lg) {
		order: 2;
	}
}

.header__nav--primary.is-open {
	visibility: visible;
	opacity: 1;
}

.header__nav--secondary {
	order: 2;
	margin-left: auto;

	@include breakpoint.up(lg) {
		order: 3;
	}
}

.header__list {
	display: flex;
}

.header__list--primary {
	flex-direction: column;
	align-items: center;

	@include breakpoint.up(sm) {
		flex-direction: row;
	}

	@include breakpoint.up(lg) {
		justify-content: center;
	}
}

.header__item {
	position: relative;
}

.header__item:not(:last-child) {
	@include breakpoint.up(sm) {
		padding-right: spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		padding-right: 0;
	}

	@include breakpoint.up(xl) {
		padding-right: spacing.generate(4);
	}
}

.header__item--primary {
	padding-right: 0;
	margin: spacing.generate(4);

	@include breakpoint.up(sm) {
		margin: 0;
	}
}

.header__item--secondary:not(:last-child) {
	padding-right: spacing.generate(4);

	@include breakpoint.up(md) {
		padding-right: spacing.generate(2);
	}

	@include breakpoint.up(lg) {
		padding-right: 0;
	}

	@include breakpoint.up(xl) {
		padding-right: spacing.generate(4);
	}
}

.header__link {
	padding: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);
	text-decoration: none;
	color: map.get(colors.$grays, 800);

	@include breakpoint.up(sm) {
		padding: 0;
	}

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 0);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(2);
	}

	&:hover,
	&:focus {
		color: colors.$red;
	}

	&:active {
		color: colors.$red;
	}
}

.header__link--icon {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0;

	@include breakpoint.up(lg) {
		padding: spacing.generate(2);
	}
}

.header__link--icon > svg {
	width: units.px2rem(26px);
	height: auto;
}

.header__link--icon-favorite {
	margin-right: spacing.generate(1);
	color: colors.$blue;
}

.header__link--text {
	display: none;

	@include breakpoint.up(md) {
		display: block;
		margin-left: spacing.generate(2);
	}
}

.header__badge {
	position: absolute;
	top: units.px2rem(-8px);
	left: spacing.generate(3);
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: units.px2rem(19px);
	height: units.px2rem(19px);
	padding: units.px2rem(2px) units.px2rem(4px);
	font-size: map.get(typography.$font-size-values, -2);
	line-height: units.px2rem(10px);
	text-align: center;
	color: colors.$white;
	border-radius: 50%;
	background: colors.$red;

	@include breakpoint.up(lg) {
		top: units.px2rem(1px);
		left: spacing.generate(5);
	}
}

.header__dropdown-items {
	position: absolute;
	right: 0;
	z-index: 1;
	min-width: units.px2rem(270px);
	border: borders.$width solid map.get(colors.$grays, 200);
	background-color: colors.$white;

	@include breakpoint.up(md) {
		right: unset;
	}
}

.header__dropdown-item:not(:last-child) {
	border-bottom: borders.$width solid map.get(colors.$grays, 200);
}

.header__dropdown-item > a {
	display: block;
	padding: spacing.generate(2) spacing.generate(3);
	text-decoration: none;
	color: map.get(colors.$grays, 800);
}

.header__menu {
	position: relative;
	display: block;
	width: units.px2rem(32px);
	height: units.px2rem(32px);
	padding: spacing.generate(4);

	@include breakpoint.up(sm) {
		display: none;
	}

	&::before,
	&::after {
		@include transitions.default('transform');

		content: '';
		position: absolute;
		top: units.px2rem(22px);
		left: 0;
		width: units.px2rem(30px);
		height: units.px2rem(1px);
		background-color: map.get(colors.$grays, 800);
	}

	&::before {
		transform: translateY(units.px2rem(6px));
	}
}

.header__menu.is-open {
	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}
