@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography' as typography-settings;
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/typography';
@use '../tools/units';

.range-slider {
	position: absolute;
	bottom: 0;
	display: block;
	width: 100%;
	height: units.px2rem(5px);
	touch-action: none;
	border-radius: borders.$radius;
	background: map.get(colors.$grays, 200);
	user-select: none;
	cursor: pointer;
}

.range-slider[data-vertical] {
	width: units.px2rem(8px);
	height: 100%;
}

.range-slider[data-disabled] {
	opacity: 0.5;
	cursor: not-allowed;
}

.range-slider .range-slider__thumb {
	position: absolute;
	top: 50%;
	z-index: 3;
	width: units.px2rem(18px);
	height: units.px2rem(18px);
	border-radius: 50%;
	background: colors.$blue;
	transform: translate(-50%, -50%);
}

.range-slider .range-slider__thumb:focus-visible {
	outline: 0;
	box-shadow: 0 0 0 units.px2rem(6px) rgb(33 150 243 / 50%);
}

.range-slider[data-vertical] .range-slider__thumb {
	left: 50%;
}

.range-slider .range-slider__thumb[data-disabled] {
	z-index: 2;
}

.range-slider .range-slider__range {
	position: absolute;
	top: 50%;
	z-index: 1;
	width: 100%;
	height: 100%;
	background: colors.$blue;
	transform: translate(0, -50%);
}

.range-slider[data-vertical] .range-slider__range {
	left: 50%;
	transform: translate(-50%, 0);
}

/* stylelint-disable */
.range-slider input[type='range'] {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	appearance: none;
	width: 0;
	height: 0;
	background-color: transparent;
	pointer-events: none;
}

.range-slider input[type='range']::-webkit-slider-thumb {
	appearance: none;
}

.range-slider input[type='range']::-moz-range-thumb {
	width: 0;
	height: 0;
	border: 0;
}

.range-slider input[type='range']:focus {
	outline: 0;
}
/* stylelint-enable */

.range-slider-output {
	position: absolute;
	bottom: spacing.generate(6);
	padding: spacing.generate(1);
	font-size: map.get(typography-settings.$font-size-values, -1);
	white-space: nowrap;
	color: colors.$white;
	background-color: colors.$black;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 40%;
		border-width: spacing.generate(1);
		border-style: solid;
		border-color: colors.$black transparent transparent transparent;
		transform: translateX(-50%);
	}
}
