@use 'sass:map';
@use '../tools/spacing';
@use '../tools/units';

.privacy__wrap ul {
	padding-left: 2rem;
	list-style-type: disc;
}

.privacy__wrap ul > li {
	padding-bottom: spacing.generate(1);
	list-style-type: disc;
}

.privacy__list {
	list-style-type: lower-roman;
}

.privacy__spacing-none.privacy__spacing-none {
	margin-bottom: 0;
}
