@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.cart-confirmation__check {
	margin-block: spacing.generate(10) spacing.generate(3);
	color: colors.$blue;
}

.cart-confirmation__headline {
	font-size: map.get(typography.$font-size-values, 10);
	color: colors.$blue;
}

.cart-confirmation__line {
	font-size: map.get(typography.$font-size-values, 5);
	color: colors.$blue;
}

.cart-confirmation__text {
	margin-block: spacing.generate(11);
	font-size: map.get(typography.$font-size-values, 1);
}

.cart-confirmation__infobox {
	padding: spacing.generate(5) spacing.generate(6);
	margin-bottom: spacing.generate(8);
	font-size: map.get(typography.$font-size-values, 1);
	text-align: center;
	color: colors.$white;
	border-radius: map.get(borders.$radius-values, large);
	background-color: colors.$blue;
}

.cart-confirmation__subscribe {
	padding: spacing.generate(4) spacing.generate(1);
	margin-bottom: spacing.generate(12);
	font-size: map.get(typography.$font-size-values, 1);
	text-align: center;
	border-radius: map.get(borders.$radius-values, large);
	background-color: map.get(colors.$grays, 200);

	@include breakpoint.up(sm) {
		padding: spacing.generate(7) spacing.generate(12) spacing.generate(4);
	}
}

.cart-confirmation__form {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(1);
	align-items: center;
	max-width: 100%;
	margin-top: spacing.generate(5);

	@include breakpoint.up(sm) {
		flex-direction: row;
		gap: spacing.generate(6);
		max-width: units.px2rem(550px);
		margin-inline: auto;
	}
}

.cart-confirmation__form > div {
	width: units.px2rem(260px);
}
