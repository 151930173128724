@use '../settings/colors';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.logo {
	display: inline-flex;
	justify-self: center;
	width: units.px2rem(145px);
	text-decoration: none;
	color: colors.$black;

	@include breakpoint.up(md) {
		width: units.px2rem(170px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(180px);
		margin-right: spacing.generate(2);
	}

	@include breakpoint.up(xl) {
		margin-right: spacing.generate(4);
	}
}

.logo > svg {
	height: 100%;
}

.logo--cart {
	display: none;

	@include breakpoint.up(md) {
		display: inline-flex;
	}
}

.logo--inverse {
	color: colors.$white;
}
