@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';

.price-box {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-top: spacing.generate(4);
	margin-top: spacing.generate(2);
}

.price-box__content-wrap {
	margin-right: spacing.generate(8);
	text-align: right;
}

.price-box__content {
	display: none;
	flex: 1 1 auto;
	flex-direction: column;
	margin-bottom: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(sm) {
		padding-top: spacing.generate(2);
		padding-bottom: spacing.generate(2);
		margin-right: spacing.generate(4);
		margin-bottom: 0;
	}

	&:last-of-type {
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.price-box__content.is-active {
	display: flex;
}

.price-box__content--order.is-active + .price-box__content--demand.is-active {
	border-top: borders.$width solid map.get(colors.$grays, 700);
}

.price-box__title {
	font-size: map.get(typography.$font-size-values, -2);
}

.price-box__price,
.price-box__demand,
.price-box__text,
.price-box__text-sm {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.price-box__price {
	font-size: map.get(typography.$font-size-values, 3);
}

.price-box__demand {
	font-size: map.get(typography.$font-size-values, 2);
}

.price-box__text {
	font-size: map.get(typography.$font-size-values, -1);
}

.price-box__text-sm {
	font-size: map.get(typography.$font-size-values, -2);
}
