@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/hover';
@use '../tools/spacing';
@use '../tools/units';

.product {
	width: 100%;
	max-width: units.px2rem(397px);
	padding: spacing.generate(2) spacing.generate(1);

	@media (min-width: units.px2rem(375px)) {
		width: 50%;
	}

	@include breakpoint.up(lg) {
		width: calc(100% / 3);
		padding: spacing.generate(2);
	}
}

.product__box {
	position: relative;
	width: 100%;
	height: 100%;
	border: borders.$width solid map.get(colors.$grays, 200);
	border-radius: units.px2rem(8px);
	background: colors.$white;
	box-shadow: 0 units.px2rem(2px) units.px2rem(6px) rgb(0 0 0 / 10%);
}

.product--highlight-cart > .product__box {
	outline: map.get(borders.$width-values, large) solid colors.$red;
}

.product--highlight-visited > .product__box {
	outline: map.get(borders.$width-values, large) solid #{rgba(colors.$blue, 0.35)};
}

.product--highlight > .product__box {
	outline: map.get(borders.$width-values, large) solid colors.$blue;
}

.product__content {
	width: 100%;
	padding: units.px2rem(20px) units.px2rem(8px) units.px2rem(12px);
	margin-top: units.px2rem(-8px);

	@include breakpoint.up(md) {
		padding: units.px2rem(23px) units.px2rem(15px) units.px2rem(15px);
	}
}

.product__figure__border-top img {
	border-top-left-radius: units.px2rem(8px);
	border-top-right-radius: units.px2rem(8px);
}

.product__figure__border-left img {
	border-top-left-radius: units.px2rem(8px);
	border-bottom-left-radius: units.px2rem(8px);
}

.product__stick {
	position: absolute;
	top: 0;
	left: units.px2rem(12px);
	padding: units.px2rem(11px) units.px2rem(8px) units.px2rem(3px);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -2);
	text-transform: uppercase;
	color: colors.$white;
	border-bottom-right-radius: units.px2rem(8px);
	border-bottom-left-radius: units.px2rem(8px);
	background: colors.$red;
}

.cart__img {
	max-width: 100%;
}

.product__img {
	width: 100%;
	height: units.px2rem(140px);
	object-fit: cover;

	@include breakpoint.up(xs) {
		height: units.px2rem(110px);
	}

	@include breakpoint.up(sm) {
		height: units.px2rem(175px);
	}
}

.product__header {
	position: relative;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	width: 100%;
	padding-bottom: spacing.generate(3);
	margin-bottom: units.px2rem(8px);

	@include breakpoint.up(md) {
		flex-flow: row nowrap;
		margin-bottom: units.px2rem(15px);
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: -2%;
		width: 104%;
		height: units.px2rem(2px);
		background-image: linear-gradient(to right, map.get(colors.$grays, 300) 50%, transparent 50%);
		background-position: left bottom;
		background-size: units.px2rem(4px) units.px2rem(2px);
		background-repeat: repeat-x;
	}
}

.product__title {
	padding-right: spacing.generate(2);
	margin: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -2);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		min-width: 65%;
		font-size: map.get(typography.$font-size-values, 0);
	}
}

.product__subtitle {
	display: block;
	font-weight: map.get(typography.$font-weight-values, regular);
}

.product__link {
	text-decoration: none;
	color: map.get(colors.$grays, 800);

	@include hover.generate() {
		&:hover {
			color: colors.$red;
		}
	}
}

.product__address {
	display: flex;
	flex-direction: row;
	margin-top: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, -4);

	@include breakpoint.up(md) {
		flex-direction: column;
		margin-top: 0;
		font-size: map.get(typography.$font-size-values, -2);
		line-height: units.px2rem(20px);
		text-align: right;
	}
}

.product__address-col {
	display: flex;
	flex-flow: column wrap;
	width: 60%;
}

.product__address-col:first-child {
	width: 40%;
}

.product__address-location {
	display: flex;
	gap: units.px2rem(3px);
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	font-size: map.get(typography.$font-size-values, -4);

	@include breakpoint.up(md) {
		display: none;
	}
}

.product__price {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.product__price-sale {
	width: 100%;
	margin-bottom: units.px2rem(1px);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	color: colors.$blue;

	@include breakpoint.up(md) {
		margin-bottom: units.px2rem(12px);
		font-size: map.get(typography.$font-size-values, 2);
	}

	@include breakpoint.up(lg) {
		width: 66%;
	}
}

.product__price-sale--halfMonth {
	position: relative;
	padding-right: spacing.generate(3);
	margin-left: spacing.generate(1);

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: units.px2rem(12px);
		height: units.px2rem(12px);
		transform: translateY(-50%);
	}

	&::before {
		border-width: 0 0 units.px2rem(12px) units.px2rem(12px);
		border-style: solid;
		border-color: colors.$blue white;
		transform: translateY(-50%) rotate(135deg);
	}

	&::after {
		border-radius: units.px2rem(12px);
		box-shadow:
			0 0 0 units.px2rem(1px) white,
			0 0 0 units.px2rem(3px) colors.$blue;
	}
}

.product__location {
	display: none;

	@include breakpoint.up(md) {
		display: flex;
		gap: spacing.generate(1);
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		margin-bottom: spacing.generate(2);
		font-size: map.get(typography.$font-size-values, -1);
		text-align: right;
		text-decoration: line-through;
		color: map.get(colors.$grays, 600);
	}

	@include breakpoint.up(lg) {
		width: 33%;
	}
}

.product__price-day {
	display: inline-flex;
	align-items: center;
	font-size: map.get(typography.$font-size-values, -2);
}

.product__price-day-icon {
	margin-top: units.px2rem(2px);
	margin-right: units.px2rem(9px);
}

.product__favourite {
	font-size: 0;
	text-align: right;
	color: map.get(colors.$grays, 300);
}

.product__favourite--active {
	color: colors.$blue;
}

.product__request--hide {
	display: none;
}

.product--ad {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	padding: 0;
}

.product__list {
	grid-template-columns: 1fr;
}

.product__list > .product--ad {
	display: flex;
	justify-content: flex-start;
	max-width: 100%;
}

.product__list .product__box {
	display: flex;
	width: 100%;
}

.product__list .product__img {
	width: units.px2rem(175px);
	height: units.px2rem(175px);

	@include breakpoint.up(md) {
		width: units.px2rem(200px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(275px);
	}
}

.product__list .product__content {
	width: 100%;
	border-right-color: transparent;
}

.product--ad-detail {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	max-width: unset;
	padding: 0;
}

.product--ad-detail .product__box {
	display: flex;
	width: 100%;
	margin-bottom: spacing.generate(4);
}

.product--ad-detail .product__img {
	width: units.px2rem(175px);
	height: units.px2rem(175px);

	@include breakpoint.up(md) {
		width: units.px2rem(200px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(275px);
	}
}
