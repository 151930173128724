@use '../tools/spacing';

.spacing-bottom-none {
	margin-bottom: 0;
}

.spacing-bottom-small {
	margin-bottom: spacing.generate(2);
}

.spacing-bottom {
	margin-bottom: spacing.generate(3);
}

.spacing-bottom-large {
	margin-bottom: spacing.generate(6);
}

.spacing-bottom-larger {
	margin-bottom: spacing.generate(8);
}

.offset-bottom-none {
	padding-bottom: 0;
}
