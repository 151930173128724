@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/hover';
@use '../tools/spacing';
@use '../tools/units';

.footer__map {
	position: relative;
	max-width: units.px2rem(1264px);
	height: units.px2rem(528px);
	margin-inline: auto;

	@include breakpoint.up(sm) {
		height: units.px2rem(480px);
	}

	@include breakpoint.up(xl) {
		margin-bottom: spacing.generate(16);
	}
}

.footer__map-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.footer__img {
	position: absolute;
	left: 50%;
	max-height: units.px2rem(528px);
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		max-height: units.px2rem(480px);
	}
}

.footer__content {
	color: map.get(colors.$grays, 600);
	background: colors.$black;
}

.footer__title-main {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
	font-family: typography.$font-family-secondary;
	text-transform: uppercase;
	color: colors.$white;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
		line-height: map.get(typography.$line-height-values, -1);
	}
}

.footer__container {
	position: relative;
	display: flex;
	flex-flow: wrap;
	width: 100%;
	padding-block: spacing.generate(5) spacing.generate(7);

	@include breakpoint.up(md) {
		padding-block: spacing.generate(11) spacing.generate(36);
	}
}

.footer__column {
	display: inline-flex;
	flex-flow: column wrap;
	width: 50%;
	min-height: units.px2rem(125px);
	padding-block: spacing.generate(2) spacing.generate(8);

	@include breakpoint.up(md) {
		width: auto;
		padding: 0 spacing.generate(15) 0 units.px2rem(18px);
		border-left: 1px solid map.get(colors.$grays, 700);
	}

	@include breakpoint.up(xl) {
		padding-right: spacing.generate(20);
	}

	&:first-child {
		width: 100%;
		padding: 0;
		border-bottom: 1px solid map.get(colors.$grays, 700);
		border-left: 0;

		@include breakpoint.up(md) {
			border-bottom: 0;
		}

		@include breakpoint.up(lg) {
			width: auto;
			padding-right: spacing.generate(15);
		}

		@include breakpoint.up(xl) {
			padding-right: spacing.generate(20);
		}
	}

	&:last-child {
		width: 100%;
		padding-bottom: 0;
		border-top: 1px solid map.get(colors.$grays, 700);

		@include breakpoint.up(md) {
			width: auto;
			padding-right: spacing.generate(4);
			border-top: 0;
		}
	}
}

.footer__title {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
	color: map.get(colors.$grays, 300);
}

.footer__list-item {
	margin-right: spacing.generate(2);
	margin-bottom: spacing.generate(2);
}

.footer__link {
	text-decoration: none;
	color: map.get(colors.$grays, 500);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, -1);
	}

	@include hover.generate() {
		&:hover {
			color: colors.$red;
		}
	}
}

.footer__social {
	position: absolute;
	bottom: spacing.generate(17);
	left: spacing.generate(3);

	@include breakpoint.up(md) {
		position: relative;
		bottom: 0;
		left: 0;
		margin-top: spacing.generate(6);
	}
}

.footer__social-link {
	display: inline-block;
	width: units.px2rem(44px);
	height: units.px2rem(44px);
	margin-right: spacing.generate(2);
	font-size: 0;
	line-height: 0;
	color: map.get(colors.$grays, 600);

	@include breakpoint.up(md) {
		width: units.px2rem(35px);
		height: units.px2rem(35px);
	}

	@include hover.generate() {
		&:hover {
			color: colors.$red;
		}
	}
}

.footer__social-link > svg {
	width: 100%;
	height: 100%;
}

.footer__button {
	margin-top: spacing.generate(2);
	background-color: colors.$red;

	@include breakpoint.up(md) {
		margin-top: spacing.generate(1);
	}
}

.footer__copy {
	margin-top: spacing.generate(30);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(1);
	}
}

.footer__copy-text {
	font-size: map.get(typography.$font-size-values, -4);
}
