@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.contact {
	display: flex;
	flex-wrap: wrap;
}

.contact__title {
	width: 100%;
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, -2);
	font-family: typography.$font-family-secondary;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(5);
		font-size: map.get(typography.$font-size-values, 12);
	}
}

.contact__item {
	width: 100%;

	@include breakpoint.up(md) {
		position: relative;
		width: 50%;
	}
}

.contact__item--full {
	width: 100%;
}

.contact__bubble {
	position: relative;
	padding: spacing.generate(6) spacing.generate(3);
	margin-bottom: spacing.generate(4);
	text-align: center;
	border-radius: units.px2rem(18px);
	background-color: colors.$gray-light;

	@include breakpoint.up(md) {
		max-width: units.px2rem(310px);
		padding: spacing.generate(5) spacing.generate(4) spacing.generate(4) spacing.generate(4);
		margin-bottom: spacing.generate(7);
		margin-left: spacing.generate(6);
	}

	@include breakpoint.up(lg) {
		max-width: units.px2rem(425px);
		padding: spacing.generate(7) spacing.generate(6);
		margin-bottom: spacing.generate(6);
		border-radius: units.px2rem(30px);
	}
}

.contact__bubble--right {
	margin-left: auto;
}

.contact__bubble-svg {
	position: absolute;
	right: 50%;
	bottom: spacing.generate(-1);
	left: 50%;
	transform: rotate(90deg) translateX(-50%);

	@include breakpoint.up(md) {
		right: unset;
		bottom: spacing.generate(-6);
		left: spacing.generate(10);
		transform: rotate(90deg);
	}
}

.contact__bubble--right > .contact__bubble-svg {
	right: 50%;
	bottom: spacing.generate(-2);
	left: 50%;
	transform: rotate(90deg) translateX(-50%);
}

.contact__bubble-text {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
	color: colors.$black;

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.contact__bubble-text--small {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.contact__bubble-text--link {
	color: colors.$blue;
}

.contact__image {
	position: relative;
	min-height: units.px2rem(270px);

	@include breakpoint.up(md) {
		position: absolute;
		bottom: 0;
		left: 50%;
		min-height: units.px2rem(350px);
	}
}

.contact__image > svg {
	position: absolute;
	right: 50%;
	bottom: 0;
	left: 50%;
	width: units.px2rem(250px);
	height: auto;
	transform: translateX(-50%);

	@include breakpoint.up(md) {
		width: units.px2rem(305px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(350px);
	}
}

.contact__grid {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-top: spacing.generate(5);
	padding-bottom: spacing.generate(4);
	margin-top: 0;
	border-top: borders.$width solid map.get(colors.$grays, 600);
	border-bottom: borders.$width solid map.get(colors.$grays, 600);

	@include breakpoint.up(md) {
		flex-direction: row;
		align-items: flex-start;
		padding-top: spacing.generate(6);
		padding-bottom: spacing.generate(5);
	}
}

.contact__address {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.contact__career-link-title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
	color: colors.$black;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(4);
		font-size: map.get(typography.$font-size-values, 2);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.contact__career-link-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: spacing.generate(4);
	text-align: center;

	@include breakpoint.up(md) {
		align-items: start;
		justify-self: center;
		margin-bottom: 0;
		text-align: left;
	}
}

.contact__form {
	position: relative;
	padding: spacing.generate(5) spacing.generate(3) spacing.generate(6);
	margin-bottom: spacing.generate(6);
	border-radius: units.px2rem(18px);
	background-color: colors.$gray-light;

	@include breakpoint.up(md) {
		padding: spacing.generate(5) spacing.generate(4) spacing.generate(4);
		margin-bottom: spacing.generate(10);
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(8) spacing.generate(9) spacing.generate(12);
		border-radius: units.px2rem(55px);
	}
}

.contact__form-svg {
	position: absolute;
	right: 50%;
	bottom: units.px2rem(-9px);
	left: 50%;
	transform: translateX(-50%) rotate(90deg);

	@include breakpoint.up(md) {
		right: units.px2rem(-28px);
		bottom: units.px2rem(110px);
		left: unset;
		transform: none;
	}

	@include breakpoint.up(lg) {
		bottom: units.px2rem(255px);
	}
}

.contact__form-title {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		max-width: units.px2rem(280px);
		margin-inline: auto;
		text-align: center;
	}

	@include breakpoint.up(lg) {
		max-width: unset;
		margin-inline: 0;
		margin-bottom: spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 5);
		text-align: left;
	}
}

.contact__career-social {
	display: flex;
	flex-direction: row;
	gap: spacing.generate(4);

	@include breakpoint.up(lg) {
		gap: spacing.generate(4);
	}
}

.contact__career-social-link > svg {
	width: units.px2rem(45px);
	height: auto;

	@include breakpoint.up(md) {
		width: units.px2rem(38px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(52px);
	}
}

.contact__career-link-image {
	width: units.px2rem(186px);
	height: units.px2rem(186px);
	margin-inline: auto;

	@include breakpoint.up(md) {
		width: units.px2rem(82px);
		height: units.px2rem(82px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(132px);
		height: units.px2rem(132px);
		margin-inline: 0;
	}
}

.contact__form-text {
	padding-left: spacing.generate(7);
}
