@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/shadows';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.modal {
	@include transitions.default('visibility, opacity');

	position: fixed;
	inset: 0;
	z-index: 9000;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	visibility: hidden;
	opacity: 0;

	@include breakpoint.up(sm) {
		padding: spacing.generate(3);
	}
}

.modal--full {
	padding: 0;
}

.modal.is-open {
	visibility: visible;
	opacity: 1;
}

.modal__background {
	position: absolute;
	width: 100%;
	height: 100%;
}

.modal__content {
	position: relative;
	display: block;
	flex: 0 1 auto;
	width: units.px2rem(666px);
	max-width: 100%;
	min-height: 100%;
	padding: 0 spacing.generate(3);
	margin: auto;
	border: borders.$width solid map.get(colors.$grays, 600);
	background-color: colors.$white;

	@include breakpoint.up(sm) {
		height: auto;
		min-height: 0;
		padding: spacing.generate(6);
	}
}

.modal__content--wide {
	position: relative;
	display: none;
	flex: 0 1 auto;
	max-width: 100%;
	min-height: 100%;
	margin: auto;

	@include breakpoint.up(sm) {
		display: block;
		height: auto;
		min-height: 0;
	}
}

.modal__header {
	padding-bottom: spacing.generate(2);
	margin-inline: spacing.generate(-3);
	box-shadow: 0 units.px2rem(6px) units.px2rem(12px) units.px2rem(-8px) map.get(colors.$grays, 600);

	@include breakpoint.up(sm) {
		padding-right: spacing.generate(8);
		margin-inline: 0;
		box-shadow: none;
	}
}

.modal__title {
	margin-top: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	text-align: center;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 2);
		text-align: left;
	}
}

.modal__close {
	position: absolute;
	top: spacing.generate(1);
	right: spacing.generate(1);
	width: units.px2rem(30px);
	height: units.px2rem(30px);
	padding: 0;
	border: 0;
	background: none;
	cursor: pointer;

	@include breakpoint.up(sm) {
		top: spacing.generate(4);
		right: spacing.generate(6);
	}

	&::after,
	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(-2px);
		right: spacing.generate(3);
		width: units.px2rem(1px);
		height: units.px2rem(35px);
		background-color: colors.$black;
	}

	&::after {
		transform: rotate(-45deg);
	}

	&::before {
		transform: rotate(45deg);
	}
}

.modal__close--wide {
	top: spacing.generate(6);
}

.modal__body {
	padding-block: spacing.generate(2);
}

.modal__body--wide {
	border: borders.$width solid map.get(colors.$grays, 800);
	border-radius: units.px2rem(37px);
	box-shadow: shadows.$wide;
}

.modal__footer {
	display: flex;
	flex-flow: row wrap;
	gap: spacing.generate(3);
	align-self: flex-end;
	justify-content: space-between;
	padding-block: spacing.generate(9) spacing.generate(4);

	@include breakpoint.up(sm) {
		gap: spacing.generate(6);
		justify-content: center;
	}
}

.modal__text {
	padding-block: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, -1);
}

.modal--exitOffer > .modal__content {
	background-image: url('https://plakatov.cz/build/media/balloon.a79f1e95.png');
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
}

.modal--exitOffer > .modal__background {
	background-color: #{rgba(colors.$black, 0.4)};
}

.modal--exitOffer > .modal__body {
	display: flex;
	justify-content: flex-end;
}

.modal__voucher {
	width: 100%;
	padding: spacing.generate(5);
	background: #{rgba(colors.$white, 0.9)};

	@include breakpoint.up(sm) {
		width: 65%;
	}
}

.modal__footer--no-offset {
	padding-block: 0;
}

.modal__footer--center {
	justify-content: center;
}
