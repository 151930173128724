@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/header';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

@keyframes scale {
	0% {
		background-color: colors.$blue;
		opacity: 1;
		transform: scale(1);
	}

	50% {
		background-color: colors.$red;
		opacity: 1;
		transform: scale(0);
	}

	100% {
		background-color: colors.$blue;
		opacity: 1;
		transform: scale(1);
	}
}

.ad-layout {
	display: grid;
	grid-template-areas:
		'filters'
		'list';
	grid-gap: spacing.generate(6);

	@include breakpoint.up(sm) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'filters filters'
			'list map';
		margin-top: units.px2rem(20px);
	}

	@include breakpoint.up(md) {
		grid-template-columns: 3fr 4fr;
		grid-template-areas:
			'filters map'
			'list map';
		margin-top: units.px2rem(-30px);
	}

	@include breakpoint.up(lg) {
		margin-top: units.px2rem(-70px);
	}
}

.ad-layout--map {
	grid-template-areas: 'map';
}

.ad-layout__filters {
	grid-area: filters;

	@include breakpoint.up(sm) {
		min-height: units.px2rem(340px);
	}
}

.ad-layout__filters--minimized {
	min-height: unset;
}

.ad-layout__list {
	grid-area: list;

	@include breakpoint.up(md) {
		margin-top: units.px2rem(-95px);
	}
}

.ad-layout__map-wrap {
	display: block;
	grid-area: map;
	grid-row: span 3;
	min-height: 100vh;
	touch-action: none;

	@include breakpoint.up(md) {
		margin-right: spacing.generate(-3);
	}
}

.ad-layout__map-wrap--grid {
	position: absolute;
	z-index: -1;
	width: calc(100vw - #{units.px2rem(30px)});
	opacity: 0;
	pointer-events: none;

	@include breakpoint.up(sm) {
		position: initial;
		z-index: initial;
		width: initial;
		opacity: 1;
		pointer-events: initial;
	}
}

.ad-layout__map.ad-layout__map {
	position: absolute;
	width: 100%;
	height: calc(100vh - #{units.px2rem(80px)});

	@include breakpoint.up(md) {
		position: fixed;
		top: units.px2rem(80px);
		width: calc(67% - #{units.px2rem(133px)});
	}

	@include breakpoint.up(xl) {
		width: calc(67% - #{units.px2rem(205px)});
	}
}

.ad-load {
	margin-bottom: spacing.generate(6);
}

.ad-load.is-active > span {
	display: block;
	width: spacing.generate(10);
	height: spacing.generate(10);
	margin-inline: auto;
	border-radius: 50%;
	animation: scale 500ms ease-in-out 0s infinite normal;
}

.ad__nav {
	position: absolute;
	right: 0;
	left: 0;
	z-index: 80;
	display: none;
	gap: spacing.generate(2);
	align-items: flex-end;
	width: 100%;
	padding: spacing.generate(6) spacing.generate(3) spacing.generate(2) spacing.generate(3);
	font-size: map.get(typography.$font-size-values, -1);
	color: map.get(colors.$grays, 600);
	background-color: colors.$white;

	@include breakpoint.up(sm) {
		display: flex;
		margin-top: spacing.generate(-14);
	}

	@include breakpoint.up(md) {
		position: relative;
		margin-top: unset;
		margin-bottom: spacing.generate(4);
	}
}

.ad__nav--sticking {
	border: borders.$width solid map.get(colors.$grays, 200);
	border-bottom-right-radius: map.get(borders.$radius-values, large);
	border-bottom-left-radius: map.get(borders.$radius-values, large);

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(0);
	}

	@include breakpoint.up(md) {
		margin-top: unset;
	}
}

.ad__nav a {
	text-decoration: none;
	color: map.get(colors.$grays, 600);

	&:hover {
		color: colors.$blue;
	}

	&:active {
		color: colors.$blue;
	}
}

.ad__nav__view-state {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(1);
	align-items: center;
	font-size: map.get(typography.$font-size-values, -2);
}

.ad__nav__view-state > svg {
	display: block;
}

.ad__nav__sorting-wrapper {
	position: relative;
	display: flex;
	align-self: center;
	justify-content: space-between;
	padding-top: spacing.generate(4);
	padding-right: spacing.generate(5);
	margin-left: spacing.generate(5);
}

.ad__nav__sorting-wrapper::before {
	content: 'Řazení';
	position: absolute;
	top: 0;
	left: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
}

.ad__nav__sorting-list {
	position: absolute;
	top: spacing.generate(8);
	right: 0;
	z-index: 100;

	@include breakpoint.up(sm) {
		left: spacing.generate(-2);
	}
}

.ad__nav__sorting-item {
	min-width: units.px2rem(120px);
	border: borders.$width solid map.get(colors.$grays, 200);
	background-color: colors.$white;
}

.ad__nav__sorting-item:not(:last-child) {
	border-bottom: 0;
}

.ad__nav__sorting-link {
	display: block;
	padding: spacing.generate(1) spacing.generate(2);
}

.ad_nav_sorting-icon {
	position: absolute;
	right: 0;
}

.ad__nav__view-state.is-active {
	color: colors.$blue;
}

.ad__nav > li:first-child {
	flex-grow: 5;
	align-self: center;
}

.ad__wrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: spacing.generate(4) spacing.generate(2);
	width: 100%;

	@include breakpoint.up(sm) {
		gap: spacing.generate(4);
	}
}

.ad__next-items {
	width: 100%;
	text-align: center;
}

.ad__item-count {
	margin: spacing.generate(5);
	margin-inline: auto;
	font-size: map.get(typography.$font-size-values, -1);
	text-align: center;
	color: map.get(colors.$grays, 600);
}

.ad__favourite-title {
	margin-top: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 5);
}

.ad-layout--map > .ad-layout__map-wrap {
	width: 100%;
	min-height: calc(100vh - #{units.px2rem(80px)});
	margin-bottom: spacing.generate(-12);
}

.ad-layout--map > .ad-layout__map-wrap > .ad-layout__map {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;

	@include breakpoint.up(sm) {
		top: units.px2rem(40px);
	}

	@include breakpoint.up(lg) {
		top: 0;
	}
}

.ad-layout__view {
	position: fixed;
	bottom: spacing.generate(10);
	left: 50%;
	z-index: 200;
	padding: spacing.generate(2) spacing.generate(4);
	border: borders.$width solid colors.$blue;
	border-radius: borders.$radius;
	background-color: colors.$white;
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		position: absolute;
		top: units.px2rem(40px);
		bottom: unset;
		padding: spacing.generate(1) spacing.generate(3);
		border-color: colors.$white;
		border-radius: 0 0 map.get(borders.$radius-values, large) map.get(borders.$radius-values, large);
	}

	@include breakpoint.up(lg) {
		top: 0;
	}
}

.ad-layout__view-item {
	display: inline-block;
	color: map.get(colors.$grays, 600);

	&:hover {
		color: colors.$blue;
	}
}

.ad-layout__view-item.is-active {
	color: colors.$blue;
}

.ad-layout__view-item:not(:last-child) {
	margin-right: spacing.generate(6);

	@include breakpoint.up(sm) {
		margin-right: spacing.generate(2);
	}
}

.ad-layout__view-grid > svg {
	width: units.px2rem(24px);
	height: units.px2rem(24px);
}

.ad-layout__view-map > svg {
	width: units.px2rem(33px);
	height: units.px2rem(30px);
}
