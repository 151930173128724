@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.error {
	margin-top: spacing.generate(2);

	@include breakpoint.up(md) {
		max-width: units.px2rem(500px);
		margin-top: spacing.generate(10);
		margin-right: auto;
	}
}

.error__image {
	width: units.px2rem(275px);
	height: auto;

	@include breakpoint.up(md) {
		width: units.px2rem(440px);
	}
}

.error__title {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
	letter-spacing: units.px2rem(-0.32px);
	text-align: center;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(4);
		text-align: left;
	}
}

.error__text {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);
	color: map.get(colors.$grays, 800);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 3);
	}
}

.error__footer {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(4);
	margin-top: spacing.generate(4);

	@include breakpoint.up(md) {
		gap: spacing.generate(3);
	}
}
