@use '../settings/colors';
@use '../settings/typography' as typography-settings;
@use '../tools/spacing';
@use '../tools/typography';

html {
	overflow-x: hidden;
	font-size: typography-settings.$font-size-base;
}

body {
	@include typography.generate(body);

	overflow-x: hidden;
	background-color: colors.$white;
}

ul {
	@include typography.list-unstyled();
}

a {
	@include typography.link();
}

h1,
h2,
h3,
h4 {
	margin-bottom: spacing.generate(3);
}

h1 {
	@include typography.generate(heading1);
}

h2 {
	@include typography.generate(heading2);
}

h3 {
	@include typography.generate(heading3);
}

address {
	font-style: normal;
}

input,
button {
	font-family: inherit;
	color: inherit;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
