@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.checkbox {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	min-height: units.px2rem(25px);
	padding-left: spacing.generate(7);
	margin-bottom: spacing.generate(4);
	cursor: pointer;
}

.checkbox__text {
	font-size: map.get(typography.$font-size-values, -1);

	&::before {
		@include transitions.default(border-color);

		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: units.px2rem(25px);
		height: units.px2rem(25px);
		border: borders.$width solid map.get(colors.$grays, 400);
		border-radius: borders.$radius;
		background-color: colors.$white;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: units.px2rem(7px);
		left: units.px2rem(9px);
		display: none;
		width: units.px2rem(8px);
		height: units.px2rem(14px);
		border: solid map.get(colors.$grays, 800);
		border-width: 0 units.px2rem(2.5px) units.px2rem(2.5px) 0;
		transform: rotate(45deg);
	}
}

.checkbox > input {
	display: none;
}

.checkbox > input:checked ~ .checkbox__text::before {
	border-color: map.get(colors.$grays, 800);
}

.checkbox > input:checked ~ .checkbox__text::after {
	display: block;
}

.checkbox.is-invalid > .checkbox__text {
	margin-block: spacing.generate(1);
}
