@use 'sass:map';
@use '../settings/colors';
@use '../settings/fonts';
@use '../tools/units';

$font-weight-values: (
	regular: 400,
	medium: 500,
	bold: 700,
	extrabold: 800,
);

$line-height-values: (
	-2: 0.8,
	-1: 1,
	0: 1.2,
	1: 1.4,
);

$font-size-base: 100%;
$font-weight-base: map.get($font-weight-values, regular);
$font-family-primary: inter, helvetica, arial, sans-serif;
$font-family-secondary: zuume, helvetica, arial, sans-serif;
$line-height-base: map.get($line-height-values, 0);

$font-size-values: (
	-5: units.px2rem(9px),
	-4: units.px2rem(10px),
	-3: units.px2rem(11px),
	-2: units.px2rem(12px),
	-1: units.px2rem(14px),
	0: units.px2rem(16px),
	1: units.px2rem(18px),
	2: units.px2rem(20px),
	3: units.px2rem(24px),
	4: units.px2rem(28px),
	5: units.px2rem(32px),
	6: units.px2rem(35px),
	7: units.px2rem(40px),
	8: units.px2rem(43px),
	9: units.px2rem(50px),
	10: units.px2rem(60px),
	11: units.px2rem(86px),
	12: units.px2rem(100px),
	13: units.px2rem(126px),
);

$styles: (
	body: (
		default: (
			color: map.get(colors.$grays, 800),
			font-family: $font-family-primary,
			font-weight: map.get($font-weight-values, regular),
			font-size: map.get($font-size-values, 0),
			line-height: map.get($line-height-values, 0),
		),
	),
	paragraph: (
		default: (
			font-size: map.get($font-size-values, -1),
			line-height: map.get($line-height-values, 0),
		),
		breakpoints: (
			lg: (
				font-size: map.get($font-size-values, 2),
			)
		)
	),
	heading1: (
		default: (
			color: colors.$black,
			font-weight: map.get($font-weight-values, bold),
			font-size: map.get($font-size-values, 3),
			line-height: map.get($line-height-values, 0),
		),
	),
	heading2: (
		default: (
			color: colors.$black,
			font-weight: map.get($font-weight-values, bold),
			font-size: map.get($font-size-values, 4),
			line-height: map.get($line-height-values, 0),
		),
		breakpoints: (
			lg: (
				font-size: map.get($font-size-values, 5),
			)
		)
	),
	heading3: (
		default: (
			font-weight: map.get($font-weight-values, bold),
			font-size: map.get($font-size-values, -1),
			line-height: map.get($line-height-values, 1),
		),
		breakpoints: (
			lg: (
				font-size: map.get($font-size-values, 3),
			)
		)
	),
	button: (
		default: (
			font-weight: map.get($font-weight-values, bold),
			font-size: map.get($font-size-values, 0),
		)
	),
);
