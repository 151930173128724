@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/header';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.filter {
	position: relative;
	z-index: 100;
	margin-bottom: spacing.generate(8);

	@include breakpoint.up(sm) {
		padding: spacing.generate(7) spacing.generate(10);
		margin-bottom: spacing.generate(8);
		border: borders.$width solid map.get(colors.$grays, 200);
		border-radius: map.get(borders.$radius-values, large);
	}
}

.filter--minimized {
	@include breakpoint.up(sm) {
		padding: 0;
		margin-bottom: spacing.generate(10);
		border: 0;
	}
}

.filter--sticky {
	position: fixed;
	z-index: 200;
	overflow-y: auto;
	border-radius: 0;
	background-color: colors.$white;

	@include breakpoint.up(sm) {
		top: header.$height;
	}
}

.filter__container {
	@include breakpoint.up(md) {
		max-width: units.px2rem(570px);
	}
}

.filter__search-target {
	@include transitions.default('color');

	position: absolute;
	top: spacing.generate(2);
	right: 0;
	appearance: none;
	display: inline-flex;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	height: units.px2rem(32px);
	padding: spacing.generate(1) spacing.generate(3) spacing.generate(1) spacing.generate(1);
	color: map.get(colors.$grays, 600);
	border: 0;
	background: transparent;
	outline: 0;
	box-shadow: none;
	cursor: pointer;

	&:hover,
	&:focus {
		color: colors.$blue;
	}
}

.filter__search-target-text {
	display: none;

	@include breakpoint.up(md) {
		display: inline-flex;
		margin-left: spacing.generate(2);
		font-weight: map.get(typography.$font-weight-values, bold);
		font-size: map.get(typography.$font-size-values, -1);
	}
}

.filter__tag-wrap {
	@include transitions.default('visibility, opacity, transform');

	display: flex;
	flex-flow: row wrap;
	gap: spacing.generate(1);
	align-items: center;
	visibility: visible;
	opacity: 1;
}

.filter__tag-wrap--closed {
	height: 0;
	visibility: hidden;
	opacity: 0;
	transform: translateY(spacing.generate(-4));
}

.filter__tag {
	padding: spacing.generate(1) spacing.generate(2);
	font-size: map.get(typography.$font-size-values, -2);
	text-decoration: none;
	color: map.get(colors.$grays, 600);
	border: borders.$width solid map.get(colors.$grays, 400);
	border-radius: borders.$radius;
	cursor: pointer;
}

.filter__tag-wrap--limited > .filter__tag {
	display: none;
}

.filter__tag-wrap--limited > .filter__tag:nth-child(-n+5) {
	display: block;
}

.filter__notification {
	padding-left: spacing.generate(3);
	margin-top: spacing.generate(-3);
	font-size: map.get(typography.$font-size-values, -1);
	color: colors.$blue;
}

.filter__notification--hidden {
	display: none;
}

.filter__link {
	display: inline-block;
	padding: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, -2);
	text-decoration: underline;
	color: map.get(colors.$grays, 600);
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.filter__tag-input {
	appearance: none;
	display: none;
	border: 0;
	background: transparent;
	outline: 0;
	box-shadow: none;
	cursor: pointer;
}

.filter__tag-input:checked ~ .filter__tag,
.filter__tag--active {
	color: colors.$white;
	background-color: map.get(colors.$grays, 600);
}

.filter__row,
.filter__row-grid {
	padding-block: spacing.generate(2);
}

.filter__row-sm-up {
	display: none;

	@include breakpoint.up(sm) {
		display: block;
	}
}

.filter__row-grid {
	position: relative;
	display: none;
	border-top: borders.$width solid map.get(colors.$grays, 200);

	@include breakpoint.up(sm) {
		display: grid;
		grid-template-columns: units.px2rem(90px) auto;
		gap: spacing.generate(7);
		align-items: start;
		border-top: 0;
	}
}

.filter__row-grid--sm {
	@include breakpoint.up(sm) {
		display: none;
	}
}

.filter__row-grid--end {
	@include breakpoint.up(sm) {
		align-items: end;
	}
}

.filter__row__title {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(sm) {
		margin-block: spacing.generate(1) 0;
	}
}

.filter__show-btn,
.filter__hide-btn {
	position: absolute;
	bottom: units.px2rem(-35px);
	left: 50%;
	z-index: 90;
	display: none;
	gap: spacing.generate(3);
	align-items: center;
	justify-content: center;
	width: units.px2rem(150px);
	height: units.px2rem(35px);
	font-size: map.get(typography.$font-size-values, -1);
	text-decoration: none;
	color: map.get(colors.$grays, 600);
	border: borders.$width solid map.get(colors.$grays, 200);
	border-top: 0;
	border-bottom-right-radius: map.get(borders.$radius-values, large);
	border-bottom-left-radius: map.get(borders.$radius-values, large);
	background-color: colors.$white;
	transform: translateX(-50%);
	cursor: pointer;

	@include breakpoint.up(sm) {
		display: flex;
	}

	&:hover {
		color: colors.$blue;
	}
}

.filter__hide-btn--hidden {
	bottom: 0;
	border: 0;
}

.filter__show-btn--scroll {
	bottom: units.px2rem(-38px);
}

.filter__hide-btn--hidden,
.filter__show-btn--scroll {
	height: units.px2rem(35px);
	padding-top: 0;
}

.filter__hide-btn.hidden {
	display: none;
}

.filter__show-btn--expanded.filter__show-btn--expanded {
	position: absolute;
	left: 50%;
}

.filter__accordion {
	position: absolute;
	top: units.px2rem(10px);
	right: units.px2rem(20px);
	z-index: 10;
	width: units.px2rem(25px);
	height: units.px2rem(20px);
	padding: spacing.generate(1);
	border: 0;
	background-color: transparent;
	cursor: pointer;

	@include breakpoint.up(sm) {
		display: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: units.px2rem(8px);
		left: units.px2rem(6px);
		width: units.px2rem(13px);
		height: units.px2rem(13px);
		border-right: borders.$width solid map.get(colors.$grays, 600);
		border-bottom: borders.$width solid map.get(colors.$grays, 600);
		transform: rotate(225deg);
	}
}

.filter__accordion--closed::after {
	top: 0;
	transform: rotate(45deg);
}

.filter-modal .filter {
	margin-bottom: 0;
	border: 0;
}

.filter-modal .filter__hide-btn {
	display: none;
}

.filter-modal .filter__row-grid {
	display: block;
}

.filter__hide-sticky {
	background-color: colors.$white;
	visibility: hidden;
}

.filter__hide-sticking {
	border: 0;
	visibility: visible;
}

.filter--sticky .filter__hide-btn {
	visibility: hidden;
}

.filter__link-open {
	display: inline-flex;
	gap: spacing.generate(3);
	align-items: center;
	justify-content: center;
	float: right;
	width: units.px2rem(150px);
	height: units.px2rem(35px);
	font-size: map.get(typography.$font-size-values, -1);
	text-decoration: none;
	color: map.get(colors.$grays, 600);
	border: borders.$width solid map.get(colors.$grays, 200);

	@include breakpoint.up(sm) {
		display: none;
	}

	&:hover {
		color: colors.$blue;
	}
}

.filter__link-open--map {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 200;
	display: inline-flex;
	border-bottom-right-radius: map.get(borders.$radius-values, large);
	border-bottom-left-radius: map.get(borders.$radius-values, large);
	background-color: colors.$white;
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		top: units.px2rem(40px);
		display: inline-flex;
		transform: translateX(units.px2rem(-300px));
	}

	@include breakpoint.up(lg) {
		top: 0;
	}
}

.filter__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include breakpoint.up(sm) {
		display: none;
	}
}

.filter__count {
	font-size: map.get(typography.$font-size-values, -1);
	color: map.get(colors.$grays, 600);
}

.filter__histogram-wrap {
	position: relative;
	display: flex;
	margin-inline: spacing.generate(6);

	@include breakpoint.up(sm) {
		margin-inline: 0;
	}
}

.filter__histogram-wrap > span {
	flex-grow: 1;
	align-self: baseline;
	max-height: units.px2rem(50px);
	background-color: map.get(colors.$grays, 200);
}

.filter__histogram-wrap > span:not(:last-child) {
	margin-right: units.px2rem(1px);
}

.filter__wrap {
	display: flex;
	flex-direction: column;

	@include breakpoint.up(sm) {
		display: initial;
	}
}

.filter__price {
	order: 1;

	@include breakpoint.up(sm) {
		order: initial;
	}
}
