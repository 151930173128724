@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.intro {
	margin-block: spacing.generate(6) spacing.generate(7);

	@include breakpoint.up(sm) {
		margin-block: spacing.generate(17) spacing.generate(10);
	}
}

.intro__wrapper {
	display: flex;
	justify-content: space-between;
}

.intro__title {
	padding-right: spacing.generate(10);
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;
	text-transform: uppercase;
	color: colors.$black;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 9);
	}

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 10);
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 11);
	}

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 12);
	}
}

.intro__perex {
	max-width: units.px2rem(570px);
	padding-right: spacing.generate(5);
	margin-top: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(sm) {
		padding-right: spacing.generate(10);
		font-size: map.get(typography.$font-size-values, 1);
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.intro__img {
	position: relative;
	display: none;

	@include breakpoint.up(sm) {
		display: flex;
		align-items: flex-end;
		width: units.px2rem(350px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(430px);
	}

	@include breakpoint.up(xl) {
		width: units.px2rem(530px);
	}
}

.intro__count {
	position: absolute;
	top: units.px2rem(80px);
	left: units.px2rem(110px);
	display: none;
	font-weight: map.get(typography.$font-weight-values, bold);
	line-height: map.get(typography.$line-height-values, -2);
	font-family: typography.$font-family-secondary;
	color: colors.$blue;

	@include breakpoint.up(sm) {
		top: units.px2rem(100px);
		left: units.px2rem(80px);
		display: block;
		font-size: map.get(typography.$font-size-values, 9);
	}

	@include breakpoint.up(lg) {
		top: units.px2rem(90px);
		left: units.px2rem(100px);
		font-size: map.get(typography.$font-size-values, 10);
	}

	@include breakpoint.up(xl) {
		top: units.px2rem(80px);
		left: units.px2rem(110px);
		font-size: map.get(typography.$font-size-values, 11);
	}
}
