@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';

.invalid-feedback,
.is-invalid > .invalid-feedback {
	display: block;
	margin-top: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, -2);
	text-align: right;
	color: colors.$red;
}
