@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/transitions';

@mixin link() {
	@include transitions.default(color);

	text-decoration: underline;
	text-decoration-color: colors.$blue;
	color: colors.$blue;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

@mixin link-unstyled() {
	text-decoration: none;
	color: inherit;
}

@mixin list-unstyled() {
	padding: 0;
	margin: 0;
	list-style: none;
}

@mixin generate($style) {
	$style-props: map.get(typography.$styles, $style);

	@if not map.has-key(typography.$styles, $style) {
		@error "Invalid style specified! #{$style} doesn't exist. Choose one of #{map.keys(typography.$styles)}";
	}

	@each $key, $value in map.get($style-props, default) {
		#{$key}: $value;
	}

	@if map.has-key($style-props, breakpoints) {
		@each $breakpoint in map.keys(map.get($style-props, breakpoints)) {
			@include breakpoint.up($breakpoint) {
				@each $key, $value in map.get(map.get($style-props, breakpoints), $breakpoint) {
					#{$key}: $value;
				}
			}
		}
	}
}
