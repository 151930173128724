@use 'sass:map';
@use '../settings/colors';
@use '../settings/shadows';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.overlay__header {
	height: units.px2rem(60px);

	@include breakpoint.up(md) {
		height: units.px2rem(70px);
	}
}

.overlay__header--cart {
	height: units.px2rem(40px);
	box-shadow: shadows.$default;

	@include breakpoint.up(md) {
		height: units.px2rem(70px);
		box-shadow: none;
	}
}

.overlay__header__container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.overlay__grid {
	display: flex;
	flex-flow: column-reverse wrap;
	justify-content: space-between;

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

.overlay__grid--center {
	align-items: center;
}

.overlay__grid__item {
	width: 100%;

	@include breakpoint.up(md) {
		width: 50%;
	}
}

.overlay__grid__image > svg {
	display: block;
	width: auto;
	max-height: units.px2rem(350px);
	margin-inline: auto;

	@include breakpoint.up(md) {
		max-width: 100%;
		max-height: unset;
	}
}

.overlay__grid__image--offset {
	@include breakpoint.up(md) {
		margin-top: spacing.generate(40);
	}
}

.overlay__close {
	position: relative;
	display: flex;
	align-items: center;
	padding: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -1);
	text-decoration: none;
	color: colors.$black;

	@include breakpoint.up(md) {
		padding: spacing.generate(1);
	}

	&::after,
	&::before {
		content: '';
		position: absolute;
		right: spacing.generate(3);
		width: units.px2rem(1px);
		height: units.px2rem(35px);
		background-color: colors.$black;
	}

	&::after {
		transform: rotate(-45deg);
	}

	&::before {
		transform: rotate(45deg);
	}
}

.overlay__close__text {
	display: none;

	@include breakpoint.up(md) {
		display: block;
		padding-right: spacing.generate(10);
	}
}

.overlay__close > svg {
	width: units.px2rem(25px);
	height: units.px2rem(25px);

	@include breakpoint.up(md) {
		width: units.px2rem(28px);
		height: units.px2rem(28px);
	}
}

.overlay__breadcrumbs {
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
}

.overlay__breadcrumbs > li {
	position: relative;
	font-size: map.get(typography.$font-size-values, 0);
	color: map.get(colors.$grays, 600);
}

.overlay__bc-item.is-active {
	font-weight: map.get(typography.$font-weight-values, medium);
	color: colors.$black;
}

.overlay__bc-item-mobile {
	display: inline-flex;

	@include breakpoint.up(md) {
		display: none;
	}
}

.overlay__bc-item-desk {
	display: none;

	@include breakpoint.up(md) {
		display: inline-flex;
	}
}

.overlay__bc-item:not(.overlay__bc-item-desk-last, .overlay__bc-item-mobile-last) {
	padding-right: spacing.generate(6);

	&::after,
	&::before {
		content: '';
		position: absolute;
		right: spacing.generate(3);
		width: units.px2rem(1px);
		height: units.px2rem(11px);
		background-color: map.get(colors.$grays, 600);
	}

	&::after {
		top: units.px2rem(7px);
		transform: rotate(45deg);
	}

	&::before {
		transform: rotate(-45deg);
	}
}

.overlay__breadcrumbs > svg {
	margin-inline: spacing.generate(2) spacing.generate(1);
}

.overlay__bc-link {
	font-weight: 500;
	text-decoration: none;
	color: colors.$black;
}

.overlay__bc-item-hidden {
	display: none;
}
