@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.campaign {
	max-width: units.px2rem(376px);
	padding-inline: spacing.generate(3);
	margin-inline: auto;
	margin-block: spacing.generate(-8) spacing.generate(8);

	@include breakpoint.up(sm) {
		max-width: units.px2rem(600px);
		margin-block: spacing.generate(-3) spacing.generate(10);
	}

	@include breakpoint.up(xl) {
		max-width: units.px2rem(1200px);
		padding-inline: 0;
		margin-block: 0 spacing.generate(15);
	}
}

.campaign__figure {
	overflow: hidden;
	border-radius: units.px2rem(8px);
	box-shadow: units.px2rem(12px) units.px2rem(12px) units.px2rem(30px) rgb(0 0 0 / 16%);
}

.campaign__img {
	width: 100%;
}

.campaign__hero {
	position: relative;
	margin-bottom: spacing.generate(10);
	overflow: hidden;
	background-color: colors.$red;
}

.campaign__hero__background {
	position: absolute;
	inset: 0;
	z-index: 100;
}

.campaign__hero__background-image {
	width: 100%;
	height: 100%;
}

.campaign__hero__inner {
	position: relative;
	z-index: 200;
	align-self: flex-end;
	width: 100%;
	text-align: center;

	@include breakpoint.up(md) {
		align-self: center;
		margin-top: units.px2rem(30px);
	}

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.campaign__hero__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}

.campaign__hero__slide {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: units.px2rem(1200px);
	min-height: units.px2rem(725px);
	padding-inline: spacing.generate(3);
	margin-inline: auto;

	@include breakpoint.up(md) {
		align-items: flex-start;
		min-height: units.px2rem(740px);
	}
}

.campaign__hero__text {
	z-index: 200;
	max-width: units.px2rem(530px);
	padding-top: spacing.generate(7);
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 10);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;
	text-align: center;
	color: colors.$white;

	@include breakpoint.up(sm) {
		padding-top: spacing.generate(14);
	}

	@include breakpoint.up(md) {
		padding-top: spacing.generate(18);
		margin-bottom: spacing.generate(12);
		font-size: map.get(typography.$font-size-values, 13);
		text-align: left;
	}
}

.campaign__hero__image {
	position: absolute;
	bottom: 0;
	z-index: 100;
	width: auto;
	height: units.px2rem(480px);

	@include breakpoint.up(md) {
		right: units.px2rem(-55px);
		bottom: units.px2rem(-65px);
		width: units.px2rem(720px);
		height: units.px2rem(740px);
	}
}

.campaign__hero__slide-image {
	width: auto;
	height: units.px2rem(480px);

	@include breakpoint.up(md) {
		height: units.px2rem(740px);
	}
}

.campaign__hero__image > svg {
	width: units.px2rem(350px);
	max-width: 100%;
	height: auto;

	@include breakpoint.up(md) {
		width: units.px2rem(600px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(900px);
	}
}

.campaign__hero__buttons {
	position: absolute;
	bottom: units.px2rem(75px);
	z-index: 200;

	@include breakpoint.up(md) {
		position: relative;
		bottom: initial;
	}
}

.campaign__section__header {
	padding-inline: spacing.generate(4);
}

.campaign__section__title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, -1);
	font-family: typography.$font-family-secondary;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(7);
		font-size: map.get(typography.$font-size-values, 12);
	}
}

.campaign__section__text {
	margin-bottom: spacing.generate(-4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}
