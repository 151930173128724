@use 'sass:map';
@use 'frontend/scss/tools/units';

$width-values: (
	default: units.px2rem(1px),
	large: units.px2rem(3px),
);
$width: map.get($width-values, default);

$radius-values: (
	default: units.px2rem(3px),
	large: units.px2rem(8px),
);
$radius: map.get($radius-values, default);
