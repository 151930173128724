@use 'sass:map';
@use '../settings/colors';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/units';

.subscribe {
	max-width: units.px2rem(376px);
	padding-inline: spacing.generate(3);
	margin-inline: auto;

	@include breakpoint.up(sm) {
		max-width: units.px2rem(600px);
	}

	@include breakpoint.up(xl) {
		max-width: units.px2rem(1200px);
	}
}

.subscribe__box {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	overflow: hidden;
	border-radius: units.px2rem(8px);
	background-color: colors.$white;
	box-shadow: units.px2rem(12px) units.px2rem(12px) units.px2rem(30px) rgb(0 0 0 / 16%);
}

.subscribe__content {
	width: 100%;
	padding: units.px2rem(22px) units.px2rem(34px);

	@include breakpoint.up(xl) {
		width: 50%;
	}
}

.subscribe__title {
	margin-bottom: spacing.generate(3);
}

.subscribe__perex {
	margin-bottom: spacing.generate(4);
}

.subscribe__form {
	display: flex;
	flex-flow: column wrap;
	width: 100%;

	@include breakpoint.up(sm) {
		flex-flow: row nowrap;
		justify-content: space-between;
	}
}

.subscribe__textfield {
	width: 100%;

	@include breakpoint.up(sm) {
		max-width: units.px2rem(270px);
		margin-right: spacing.generate(2);
	}
}

.subscribe__button {
	max-width: units.px2rem(170px);
	margin-top: units.px2rem(16px);

	@include breakpoint.up(sm) {
		margin-top: 0;
	}
}

.subscribe__figure {
	width: 100%;
	margin-top: units.px2rem(24px);

	@include breakpoint.up(sm) {
		margin-top: 0;
	}

	@include breakpoint.up(xl) {
		width: 50%;
	}
}

.subscribe__img {
	@include breakpoint.up(xl) {
		display: block;
		margin-left: auto;
	}
}
