@use '../tools/spacing';
@use '../tools/units';

.container {
	max-width: units.px2rem(1200px);
	padding-inline: spacing.generate(3);
	margin-inline: auto;
}

.container--extra-wide {
	max-width: units.px2rem(1920px);
}

.container--widest {
	max-width: units.px2rem(1312px);
}

.container--wide {
	max-width: units.px2rem(1295px);
}

.container--narrow {
	max-width: units.px2rem(795px);
}

.container--narrow-mid {
	max-width: units.px2rem(640px);
}

.container--narrower {
	max-width: units.px2rem(595px);
}

.container--slim {
	max-width: units.px2rem(400px);
}

.container--no-offset {
	padding-inline: 0;
}
