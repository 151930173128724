@use 'sass:map';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';
@use '../tools/units';

.seo-region__wrapper {
	margin-top: units.px2rem(70px);
	color: map.get(colors.$grays, 800);
}

.seo-region__wrapper > h1 {
	font-size: map.get(typography.$font-size-values, 4);
}

.seo-region__wrapper > h2 {
	margin-block: spacing.generate(8) 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
}

.seo-region__wrapper > p {
	font-size: map.get(typography.$font-size-values, 2);
	text-align: justify;
}

.seo-region__wrapper h1 + p {
	margin-block: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, bold);
	color: colors.$blue;
}

.seo-region__perex {
	margin-top: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, bold);
	color: colors.$blue;
}
