@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/spacing';
@use '../tools/units';

.textareafield {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: spacing.generate(4);
}

.textareafield > label {
	position: absolute;
	top: units.px2rem(7px);
	left: units.px2rem(11px);
	font-size: map.get(typography.$font-size-values, -1);
	color: map.get(colors.$grays, 600);
}

.textareafield > textarea {
	width: 100%;
	min-height: units.px2rem(139px);
	padding: spacing.generate(5) spacing.generate(2) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
	border: borders.$width solid map.get(colors.$grays, 400);
	border-radius: borders.$radius;
	resize: vertical;
}

.textareafield > textarea:disabled {
	color: map.get(colors.$grays, 200);
	background-color: map.get(colors.$grays, 200);
}

.textareafield > textarea::placeholder {
	color: map.get(colors.$grays, 600);
}
