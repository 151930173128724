@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.flash {
	@include transitions.default(opacity);

	position: fixed;
	left: calc(50% - #{units.px2rem(30px)});
	z-index: 300;
	width: calc(100% - #{units.px2rem(30px)});
	max-width: units.px2rem(600px);
	padding: spacing.generate(5);
	margin-inline: spacing.generate(3);
	text-align: center;
	border-radius: borders.$radius;
	background-color: colors.$warning;
	opacity: 0;
	transform: translateX(calc(-50% + #{units.px2rem(15px)}));

	@include breakpoint.up(md) {
		padding: spacing.generate(10);
	}
}

.flash__text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	color: map.get(colors.$grays, 800);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.flash--success {
	background-color: colors.$blue;
}

.flash--success > .flash__text {
	color: colors.$white;
}

.flash--visible {
	opacity: 1;
}
