@use 'sass:color';
@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography' as typography-settings;
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/typography';
@use '../tools/units';

.btn {
	@include transitions.default('opacity background-color');
	@include typography.generate(button);

	appearance: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: units.px2rem(170px);
	padding: spacing.generate(3) spacing.generate(4);
	text-align: center;
	text-decoration: none;
	color: colors.$white;
	border: borders.$width solid transparent;
	border-radius: borders.$radius;
	background-image: none;
	background-color: colors.$blue;
	opacity: 1;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: color.adjust(colors.$blue, $lightness: -10%);
		outline: 0;
	}
}

.btn.is-disabled,
.btn[disabled] {
	appearance: none;
	opacity: 0.5;
	pointer-events: none;
	cursor: default;
}

.btn--inverse {
	color: map.get(colors.$grays, 800);
	background-color: map.get(colors.$grays, 400);

	&:hover,
	&:focus {
		background-color: color.adjust(map.get(colors.$grays, 400), $lightness: -10%);
	}
}

.btn--secondary {
	background-color: colors.$red;

	&:hover,
	&:focus {
		background-color: color.adjust(colors.$red, $lightness: -10%);
	}
}

.btn--outline-inverse {
	border-color: colors.$white;
	background-color: transparent;

	&:hover,
	&:focus {
		background-color: #{rgba(colors.$white, 0.3)};
	}
}

.btn--outline-inverse--full {
	border-color: colors.$white;
	background-color: colors.$red;

	&:hover,
	&:focus {
		background-color: colors.$black;
	}
}

.btn--outline {
	color: colors.$blue;
	border-color: colors.$blue;
	background-color: transparent;

	&:hover,
	&:focus {
		background-color: #{rgba(colors.$blue, 0.3)};
	}
}

.btn--large {
	min-width: units.px2rem(213px);
	padding-block: spacing.generate(4);

	@include breakpoint.up(lg) {
		min-width: units.px2rem(235px);
		padding-block: spacing.generate(5);
	}
}

.btn--full {
	width: 100%;
}

.btn--tall {
	min-height: units.px2rem(60px);
}

.btn--input {
	position: absolute;
	top: units.px2rem(7.5px);
	right: units.px2rem(7.5px);
	padding: spacing.generate(2) spacing.generate(3);
	font-weight: map.get(typography-settings.$font-weight-values, regular);
}

.btn--mid {
	min-height: units.px2rem(56px);
}

.btn--small {
	height: units.px2rem(48px);
	white-space: nowrap;
}

.btn--smaller {
	height: units.px2rem(36px);
	font-size: map.get(typography-settings.$font-size-values, -1);
	white-space: nowrap;
	color: map.get(colors.$grays, 600);
	border: 0;
	background-color: map.get(colors.$grays, 200);

	&:hover,
	&:focus {
		background-color: #{rgba(map.get(colors.$grays, 600), 0.3)};
	}
}

.btn--smallest {
	width: units.px2rem(80px);
	min-width: auto;
	height: units.px2rem(26px);
	padding-block: 0;
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, -1);
	text-transform: uppercase;
}

.btn--gray-dark {
	color: colors.$white;
	background-color: map.get(colors.$grays, 800);

	&:hover,
	&:focus {
		background-color: color.adjust(map.get(colors.$grays, 800), $lightness: 5%);
	}
}

.btn--gray-light {
	color: map.get(colors.$grays, 800);
	border-color: map.get(colors.$grays, 800);
	background-color: transparent;

	&:hover,
	&:focus {
		background-color: #{rgba(map.get(colors.$grays, 800), 0.3)};
	}
}

.btn--gray-lighter {
	color: map.get(colors.$grays, 600);
	border-color: map.get(colors.$grays, 600);
	background-color: transparent;

	&:hover,
	&:focus {
		background-color: #{rgba(map.get(colors.$grays, 600), 0.3)};
	}
}

.btn--modal {
	width: 100%;
	padding-inline: spacing.generate(2);
	font-size: map.get(typography-settings.$font-size-values, -1);

	@include breakpoint.up(sm) {
		width: auto;
	}
}

.btn--responsive {
	width: 100%;

	@include breakpoint.up(md) {
		width: auto;
	}
}

.btn--white {
	color: map.get(colors.$grays, 800);
	background-color: colors.$white;

	&:hover,
	&:focus {
		color: colors.$white;
		background-color: colors.$black;
	}
}

.btn--icon {
	gap: spacing.generate(1);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
}

.btn--icon > svg {
	width: units.px2rem(24px);
	height: auto;
}

.btn-loader {
	display: none;
	width: units.px2rem(20px);
	height: units.px2rem(20px);
	margin-left: spacing.generate(1);
	visibility: hidden;
	opacity: 0;
}

.btn.is-loading > .btn-loader {
	display: inline-block;
	visibility: visible;
	opacity: 1;
}

.btn--contest {
	min-width: units.px2rem(110px);
	padding: spacing.generate(2);
	font-size: map.get(typography-settings.$font-size-values, 4);
	line-height: units.px2rem(28px);
	font-family: typography-settings.$font-family-secondary;
	background-color: colors.$red;

	&:hover,
	&:focus {
		background-color: colors.$black;
	}
}

.btn--wide {
	padding-inline: spacing.generate(9);
}

.btn--font-size-1 {
	font-size: map.get(typography-settings.$font-size-values, 1);
}
