@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/hover';
@use '../tools/spacing';
@use '../tools/units';

.section {
	padding-block: spacing.generate(5) spacing.generate(12);

	@include breakpoint.up(md) {
		padding-block: spacing.generate(14) spacing.generate(12);
	}
}

.section--dense {
	padding-block: spacing.generate(8) spacing.generate(6);

	@include breakpoint.up(md) {
		padding-block: spacing.generate(10) spacing.generate(12);
	}
}

.section--gray {
	background-color: map.get(colors.$grays, 200);
}

.section--gray-half {
	background: linear-gradient(0deg, map.get(colors.$grays, 200) 50%, colors.$white 0);
}

.section__box {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	margin-inline: auto;
	overflow: hidden;
	border: borders.$width solid map.get(colors.$grays, 200);
	border-radius: units.px2rem(8px);
	background-color: colors.$white;
	box-shadow: units.px2rem(12px) units.px2rem(12px) units.px2rem(30px) rgb(0 0 0 / 6%);
}

.section__header {
	margin-bottom: spacing.generate(3);
}

.section__header--product-list {
	max-width: units.px2rem(782px);
	margin-inline: auto;

	@include breakpoint.up(lg) {
		max-width: 100%;
	}
}

.section__header--spec {
	margin-inline: spacing.generate(6);
	margin-top: spacing.generate(6);

	@include breakpoint.up(xl) {
		margin-inline: spacing.generate(12);
		margin-top: spacing.generate(12);
	}
}

.section--editor .section__title {
	max-width: units.px2rem(864px);
	margin-inline: auto;
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 3);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(6);
		font-size: calc(1.5rem + (60 - 24) * ((100vw - 36rem) / (1439 - 576))); // Responsive 24-60px for 576-1439px
		text-align: center;
	}

	@include breakpoint.up(xxl) {
		font-size: units.px2rem(60px);
	}
}

.section__perex {
	max-width: units.px2rem(570px);
	margin-bottom: spacing.generate(5);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(7);
	}
}

.section--editor .section__perex {
	margin-inline: auto;
	margin-bottom: spacing.generate(8);
	font-size: units.px2rem(18px);

	@include breakpoint.up(sm) {
		font-size: calc(1.125rem + (24 - 18) * ((100vw - 36rem) / (1439 - 576))); // Responsive 18-24px for 576-1439px
	}

	@include breakpoint.up(xxl) {
		font-size: units.px2rem(24px);
	}
}

.section__perex--line {
	position: relative;
	margin-bottom: spacing.generate(15);

	&::before {
		content: '';
		position: absolute;
		bottom: spacing.generate(-9);
		left: 0;
		width: 100%;
		max-width: units.px2rem(570px);
		height: units.px2rem(1px);
		background: map.get(colors.$grays, 600);
	}
}

.section--editor .section__content {
	max-width: units.px2rem(567px);
	margin-inline: auto;
	margin-bottom: spacing.generate(8);
}

.section__inline-wrap {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.section__nav-link {
	display: inline-block;
	margin-right: spacing.generate(1);
	margin-bottom: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, -1);
	text-decoration: none;
	color: map.get(colors.$grays, 600);

	&:first-child {
		margin-left: 0;
	}

	@include hover.generate() {
		&:hover {
			text-decoration: underline;
			color: colors.$red;
		}
	}

	&::before {
		content: '';
		position: relative;
		left: units.px2rem(4px);
		display: inline-block;
		width: units.px2rem(11px);
		height: units.px2rem(11px);
		margin-right: spacing.generate(1);
		border-bottom: borders.$width solid map.get(colors.$grays, 600);
		border-left: borders.$width solid map.get(colors.$grays, 600);
		transform: rotate(45deg);
	}
}

.section__button--invoice {
	margin-left: spacing.generate(3);
}

.section--relative {
	position: relative;
}
